import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import QuestionInputComponent from './QuestionInputComponent';
import { DEFAULT_QUESTIONS } from '../../constants/ProxyQuestionConstants';
import { ProxyUserQuestionContainerStyles } from '../../styles/proxyPageStyles';
import ProxyAuthUserSelector from '../ProxyAuthUserSelector';

const DefaultQuestionContainer = ({isDisabled}) => {
  return (
    <>
      <Typography variant='body1' gutterBottom sx={{ mb: 3 }}>
        Under regulation 6 of the Owners Corporations Regulations 2007, I/We,
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 3 }}>
        <QuestionInputComponent
          id={DEFAULT_QUESTIONS.OWNER_NAME.id}
          isDisabled={isDisabled}
          questionLabel={DEFAULT_QUESTIONS.OWNER_NAME.question}
          answerType={DEFAULT_QUESTIONS.OWNER_NAME.answerDataType}
          isRequired={DEFAULT_QUESTIONS.OWNER_NAME.isRequired}
          placeholder={DEFAULT_QUESTIONS.OWNER_NAME.placeholder}
          secondaryLabel={DEFAULT_QUESTIONS.OWNER_NAME.secondaryLabel}
        />
        <QuestionInputComponent
          id={DEFAULT_QUESTIONS.OWNER_ADDRESS.id}
          isDisabled={isDisabled}
          questionLabel={DEFAULT_QUESTIONS.OWNER_ADDRESS.question}
          answerType={DEFAULT_QUESTIONS.OWNER_ADDRESS.answerDataType}
          isRequired={DEFAULT_QUESTIONS.OWNER_ADDRESS.isRequired}
          placeholder={DEFAULT_QUESTIONS.OWNER_ADDRESS.placeholder}
        />
      </Stack>
      <Typography variant='subtitle2' gutterBottom sx={ProxyUserQuestionContainerStyles.categoryLabel}>
        Being the owner(s) of lot(s)
      </Typography>
      <QuestionInputComponent
        id={DEFAULT_QUESTIONS.LOT_NUMBERS.id}
        isDisabled={isDisabled}
        questionLabel={DEFAULT_QUESTIONS.LOT_NUMBERS.question}
        answerType={DEFAULT_QUESTIONS.LOT_NUMBERS.answerDataType}
        isRequired={DEFAULT_QUESTIONS.LOT_NUMBERS.isRequired}
        placeholder={DEFAULT_QUESTIONS.LOT_NUMBERS.placeholder}
        secondaryLabel={DEFAULT_QUESTIONS.LOT_NUMBERS.secondaryLabel}
      />
      <Typography variant='subtitle2' gutterBottom sx={ProxyUserQuestionContainerStyles.categoryLabel}>
        Authorise
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 3 }}>
        <ProxyAuthUserSelector />
        <QuestionInputComponent
          id={DEFAULT_QUESTIONS.AUTH_ADDRESS.id}
          isDisabled={true}
          questionLabel={DEFAULT_QUESTIONS.AUTH_ADDRESS.question}
          answerType={DEFAULT_QUESTIONS.AUTH_ADDRESS.answerDataType}
          isRequired={DEFAULT_QUESTIONS.AUTH_ADDRESS.isRequired}
          placeholder={DEFAULT_QUESTIONS.AUTH_ADDRESS.placeholder}
          defaultValue={DEFAULT_QUESTIONS.AUTH_ADDRESS.defaultValue}
        />
      </Stack>
    </>
  );
};

export default DefaultQuestionContainer;

DefaultQuestionContainer.defaultProps = {
  isDisabled: false,
};

DefaultQuestionContainer.propTypes = {
  isDisabled: PropTypes.bool,
};
