import React from 'react';
import { Stack, Skeleton } from '@mui/material';

const TableLoader = () => {
  return (
    <>
      <Skeleton variant='rectangular' animation='wave' sx={{ height: 100  }} />
      <Stack direction='row' spacing={2} sx={{display: 'flex', mt: 2}}>
        {Array(5).fill('').map((_, i) => (
          <Skeleton key={i} variant='rectangular' animation='wave' sx={{ flex: 1, height: 50 }} />
        ))}
      </Stack>
      {Array(6).fill('').map((_, i) => (
        <Stack key={i} direction='row' spacing={2} sx={{ display: 'flex', height: 50 }}>
          {Array(5).fill('').map((_, i) => (
            <Skeleton key={i} variant='text' animation='wave' sx={{ flex: 1 }} />
          ))}
        </Stack>
      ))}
    </>
  );
};

export default TableLoader;