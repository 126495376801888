import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import Lottie from "lottie-react";
import { AdminContext } from '../../contexts/AdminContext';
import loadingAnimation from '../../animations/loading_animation.json';
import warningAnimation from '../../animations/warning_animation.json';

const ProxySingleDeleteModal = () => {

  const { singleDeleteModalState, setSingleDeleteDialogState, deleteSingleProxy } = useContext(AdminContext);
  const { visibility, isDeleting, row } = singleDeleteModalState;

  const handleClose = () => {
    if (isDeleting) {
      return;
    }
    setSingleDeleteDialogState(false);
  };

  const handleDeleteSelect = () => {
    deleteSingleProxy(row.id);
  };

  const renderProxyData = () => {
    const { ProxyLots, lotOwner } = row;
    const lotIds = ProxyLots?.flatMap((proxyLot) => proxyLot.lotId).join(', ');
    if (!row.id) {
      return <></>;
    };
  
    return (
      <Stack sx={{ m: 2 }}>
        <Typography variant='caption'><span style={{ fontWeight: 'bold' }}>Owner Name:</span> {lotOwner[0].name}</Typography>
        <Typography variant='caption'><span style={{ fontWeight: 'bold' }}>Lot Number(s):</span> {lotIds}</Typography>
      </Stack>
    );
  };

  const renderLoader = () => {
    return (
      <>
        <Lottie
          animationData={loadingAnimation}
          loop
          autoPlay
          style={{ width: 200, height: 200, display: 'flex', alignSelf: 'center' }}
        />
        <Typography variant='body2' align='center'>Deleting record...</Typography>
      </>
    );
  };

  const renderDeleteContent = () => {
    return (
      <Stack sx={{ mt: 2 }}>
        <Lottie
          animationData={warningAnimation}
          loop={false}
          autoPlay
          style={{ width: 200, height: 200, display: 'flex', alignSelf: 'center' }}
        />
        <Typography variant='body1'>
          Are you sure want to delete proxy record?
        </Typography>
        {renderProxyData()}
        <Typography variant='caption' sx={{ color: 'warning.main', fontWeight: 'bold', mt: 1 }}>
          This action is non-reversible and can not be restored.
        </Typography>
      </Stack>
    );
  };

  return (
    <Dialog
      open={visibility}
      onClose={handleClose}
      disableEscapeKeyDown={isDeleting}
      onBackdropClick={handleClose}
    >
      <DialogContent>
        <Typography variant='body1' sx={{fontWeight: 'bold'}} gutterBottom>Delete Proxy Record</Typography>
        {isDeleting ? renderLoader() : renderDeleteContent()}
      </DialogContent>
      <DialogActions sx={{mb: 2, mr: 2}}>
        <Button variant='outlined' disabled={isDeleting} color='warning' onClick={handleDeleteSelect}>Delete</Button>
        <Button variant='contained' disabled={isDeleting} onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProxySingleDeleteModal;
