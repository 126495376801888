export const landingPageStyles = {
  container: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    pt: { xs: '30%', md: '10%' }
  },
  divider: {
    backgroundColor: '#d3d3d3',
    width: '30%',
    margin: 'auto',
    mt: 10,
    mb: 0
  },
  proxyBtnBox: {
    mt: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
};