import {downloadClient, fetchClient} from './fetchClient';

const getProxiesForms = async () => {
  try {
    const response = await fetchClient('/v1/proxy-form', {}, true);
    return response;
  } catch (error) {
    return error;
  }
};

const downloadProxyForm = async (id) => {
  try {
    const response = await downloadClient(`/v1/proxy-form/${id}/download`);
    return response;
  } catch (error) {
    return error;
  }
};

const downloadAllCSV = async (from, to) => {
  try {
    const response = await downloadClient(
      `/v1/proxy-form/download/csv?to=${encodeURIComponent(to)}&from=${encodeURIComponent(from)}`,
      true
    );
    return response;
  } catch (error) {
    return error;
  }
};

const downloadAllPDF = async (from, to) => {
  try {
    // TODO: URL to be updated!
    return await downloadClient(
        `/v1/proxy-form/download/pdf?to=${encodeURIComponent(to)}&from=${encodeURIComponent(from)}`,
        true
    );
  } catch (error) {
    return error;
  }
};


const allProxyDelete = async (ids) => {
  try {
    const response = await fetchClient(`/v1/proxy-form/delete`, {
      body: JSON.stringify({ids}),
      customConfig: {
        method: 'POST',
      }
    }, true);
    return response;
  } catch (error) {
    return error;
  }
};

export { getProxiesForms, downloadProxyForm, downloadAllCSV, downloadAllPDF, allProxyDelete };
