export const ADMIN_USER_TYPE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const TABLE_COLUMNS = [
  'Lot Number(s)',
  'Owner Name',
  'Authorised Name',
  'Email',
  'Expiry Date',
  'Description',
  'Submit Date',
  'Submit ID',
  '',
];
