import React, { useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Collapse,
  Typography,
  Stack,
  Switch,
  IconButton
} from '@mui/material';
import { 
  ArrowBackIosNewOutlined
} from '@mui/icons-material';
import { proxyRulesContainerStyles } from '../../styles/proxyPageStyles';

const EnglishRules = () => {
  return (
    <>
      <Typography variant='h5' textAlign='center' gutterBottom>Owners Corporation Proxy Form</Typography>
      <Typography variant='body1' gutterBottom>- Schedule 1, Regulation 8, Owners Corporation Regulation 2018</Typography>
      <Typography variant='body1' gutterBottom>- Owners Corporation Plan Number – PS801387G</Typography>
      <Typography variant='body1' gutterBottom>- If you are unable to attend the meeting, please nominate a proxy to represent you by filling out the proxy form below.</Typography>
      <Typography variant='body1' gutterBottom>- Please see below details in regards to proxy before filling out the form:</Typography>
      <Box sx={{ pl: 3 }}>
        <Typography variant='body1' gutterBottom>
          1. Proxies are appointed by a lot owner as their representative at meetings, to vote in ballots or represent them on the committee.
        </Typography>
        <Typography variant='body1' gutterBottom>
          2. Proxies automatically lapse 12 months after the form is delivered to the Owners Corporation Manager.
        </Typography>
        <Typography variant='body1' gutterBottom>
          3. Proxies must act honestly and in good faith and exercise due care and diligence.
        </Typography>
        <Typography variant='body1' gutterBottom>
          4. Proxies cannot transfer the proxy to another person.
        </Typography>
        <Typography variant='body1' gutterBottom>
          5. It is illegal for someone to coerce a lot owner into making another person their proxy.
        </Typography>
        <Typography variant='body1' gutterBottom>
          6. Lot owners cannot be proxies if they are in arrears
        </Typography>
        <Typography variant='body1'>
          7. A person cannot vote (as proxy or under power of attorney) on behalf of:
        </Typography>
        <Box sx={{ pl: 3 }}>
          <Typography variant='body1' gutterBottom>
            1. more than 1 lot owner if there are 20 or less occupiable lots; or
          </Typography>
          <Typography variant='body1'>
            2.  more than 5% of the lot owners if there are more than 20 occupiable lots.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ChineseRules = () => {
  return (
    <>
      <Typography variant='h5' textAlign='center' gutterBottom>业主立案法团委托书</Typography>
      <Typography variant='body1' gutterBottom>- 根据2018 年业主立案法团条例第 8 条</Typography>
      <Typography variant='body1' gutterBottom>- 业主立案法团计划编号 – PS801387G</Typography>
      <Typography variant='body1' gutterBottom>- 如果您无法出席会议，请通过填写下面的代理表格提名一位代表您的代理人。</Typography>
      <Typography variant='body1'>- 在填写表格之前，请参阅以下关于代理的详细信息：</Typography>
      <Box sx={{ pl: 3 }}>
        <Typography variant='body1' gutterBottom>
          1. 代理人由房产所有人任命为他们在会议上的代表，进行投票或在委员会中代表他们。
        </Typography>
        <Typography variant='body1' gutterBottom>
          2. 表格提交给 OC物业管理经理 12 个月后，代理将自动失效。
        </Typography>
        <Typography variant='body1' gutterBottom>
          3. 代理人必须诚实、善意地行事，并尽职尽责。
        </Typography>
        <Typography variant='body1' gutterBottom>
          4. 代理人不得将代理转让给他人。
        </Typography>
        <Typography variant='body1' gutterBottom>
          5. 强迫房产所有人委托他人代理是违法的。
        </Typography>
        <Typography variant='body1' gutterBottom>
          6. 拖欠物业费的房产所有者成为代理人授权方
        </Typography>
        <Typography variant='body1'>
          7. 一个人不能（作为代理人或授权书委托人）：
        </Typography>
        <Box sx={{ pl: 3 }}>
          <Typography variant='body1' gutterBottom>
            1. 代表超过多位房产所有者投票，如果有20个或更少的可占用地块；或者
          </Typography>
          <Typography variant='body1'>
            2. 代表超过5%的房产所有者投票，如果有超过20个可占用地块
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ProxyRulesContainer = () => {

  const [showRules, setShowRules] = useState(false); // TODO: changed to false due to submit disable
  const [showChinese, setShowChinese] = useState(false);

  const handleLanguageCheck = () => {
    setShowChinese(!showChinese);
  }

  const handleRuleContainerCollapse = () => {
    setShowRules(!showRules)
  }

  return (
    <Container sx={{ pt: 2 }}>
      <Paper sx={{...proxyRulesContainerStyles.paper, pb: showRules ? undefined : 0 }}>
        <Box sx={proxyRulesContainerStyles.collapseBtnContainer}>
          <Box>
            {!showRules && <Typography variant='body1'>Proxy Form Rules (代理表格规则)</Typography>}
          </Box>
          <IconButton onClick={handleRuleContainerCollapse}>
            <ArrowBackIosNewOutlined sx={{ transform: showRules ? 'rotate(90deg)' : 'rotate(270deg)' }} />
          </IconButton>
        </Box>
        
        <Collapse in={showRules}>
          <Stack direction="row" spacing={1} alignItems="center" sx={proxyRulesContainerStyles.collapseLanguageStack}>
            <Typography>English</Typography>
            <Switch checked={showChinese} onChange={handleLanguageCheck} />
            <Typography>中文</Typography>
          </Stack>
          {showChinese ? <ChineseRules /> :
            <EnglishRules />}
        </Collapse>
      </Paper>
    </Container>
  )
}

export default ProxyRulesContainer