import { REQUIRED_FIELD_MSG } from './CommonConstants';

export const CATEGORY_RULE = {
  ALL: 'ALL',
  AT_LEAST_ONE: 'AT_LEAST_ONE',
  NULL: null
};

export const ANSWER_TYPE = {
  TEXT: 'TEXT',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  LONG_TEXT: 'LONG_TEXT',
  SIGNATURE: 'SIGNATURE',
  BOOLEAN: 'BOOLEAN',
};

export const QUESTION_ID_MAP = {
  OWNER_NAME: 'OWNER_NAME',
  OWNER_ADDRESS: 'OWNER_ADDRESS',
  LOT_NUMBERS: 'LOT_NUMBERS',
  AUTH_NAME: 'AUTH_NAME',
  AUTH_ADDRESS: 'AUTH_ADDRESS',
  SIGN_PRINT_NAME: 'SIGN_PRINT_NAME',
  EMAIL: 'EMAIL',
  SIGN_CONSENT: 'SIGN_CONSENT'
};

export const DEFAULT_QUESTIONS = {
  [QUESTION_ID_MAP.OWNER_NAME]: {
    id: QUESTION_ID_MAP.OWNER_NAME,
    question: 'Name(s) of Lot Owners',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: 'John Smith, Anna Holden',
    isRequired: true,
    secondaryLabel: 'Multiple names should be comma (,) separated'
  },
  [QUESTION_ID_MAP.OWNER_ADDRESS]: {
    id: QUESTION_ID_MAP.OWNER_ADDRESS,
    question: 'Of (address)',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: 'Address of the owner',
    isRequired: true
  },
  [QUESTION_ID_MAP.LOT_NUMBERS]: {
    id: QUESTION_ID_MAP.LOT_NUMBERS,
    question: 'Lot number(s)',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: '435/A32, 653/D22',
    isRequired: true,
    secondaryLabel: 'Multiple lot numbers should be comma (,) separated'
  },
  [QUESTION_ID_MAP.AUTH_NAME]: {
    id: QUESTION_ID_MAP.AUTH_NAME,
    question: 'Name of person',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: 'William Brown, Olivia Jones',
    isRequired: true
  },
  [QUESTION_ID_MAP.AUTH_ADDRESS]: {
    id: QUESTION_ID_MAP.AUTH_ADDRESS,
    question: 'Of (address)',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: 'Address of the authorised',
    isRequired: true,
    defaultValue: 'Level 1/326 William St, Melbourne, VIC - 3000',
  },
  [QUESTION_ID_MAP.SIGN_PRINT_NAME]: {
    id: QUESTION_ID_MAP.SIGN_PRINT_NAME,
    question: 'Printed name(s)',
    answerDataType: ANSWER_TYPE.LONG_TEXT,
    placeholder: 'Insert names',
    isRequired: true,
  },
  [QUESTION_ID_MAP.EMAIL]: {
    id: QUESTION_ID_MAP.EMAIL,
    question: 'Email',
    answerDataType: ANSWER_TYPE.TEXT,
    placeholder: 'Insert email',
    isRequired: true
  },
  [QUESTION_ID_MAP.SIGN_CONSENT]: {
    id: QUESTION_ID_MAP.SIGN_CONSENT,
    question: 'Email',
    answerDataType: ANSWER_TYPE.BOOLEAN,
    placeholder: '',
    isRequired: true
  },
}

export const FIELD_VALIDATIONS = [
  {
    name: QUESTION_ID_MAP.OWNER_NAME,
    label: QUESTION_ID_MAP.OWNER_NAME,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.OWNER_ADDRESS,
    label: QUESTION_ID_MAP.OWNER_ADDRESS,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.LOT_NUMBERS,
    label: QUESTION_ID_MAP.LOT_NUMBERS,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.AUTH_NAME,
    label: QUESTION_ID_MAP.AUTH_NAME,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.AUTH_ADDRESS,
    label: QUESTION_ID_MAP.AUTH_ADDRESS,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.EMAIL,
    label: QUESTION_ID_MAP.EMAIL,
    validationType: 'string',
    validations: [
      {
        type: 'email',
        params: ['Invalid email!']
      },
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.SIGN_PRINT_NAME,
    label: QUESTION_ID_MAP.SIGN_PRINT_NAME,
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
  {
    name: QUESTION_ID_MAP.SIGN_CONSENT,
    label: QUESTION_ID_MAP.SIGN_CONSENT,
    validationType: 'bool',
    validations: [
      {
        type: 'required',
        params: [REQUIRED_FIELD_MSG]
      }
    ]
  },
];
