import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { ANSWER_TYPE } from '../../constants/ProxyQuestionConstants';

const TextInputComponent = React.forwardRef((props, ref) => {
  const { placeholder, answerType, formikProps, formikMeta, name, isDisabled, defaultValue } = props;
  const isMultiline = answerType === ANSWER_TYPE.LONG_TEXT;

  const handleInputChange = (e) => {
    formikProps.setFieldValue(name, e.target.value.trim());
    if (Boolean(formikMeta.error)) {
      formikProps.setFieldError(name, undefined)
    }
  };

  return (
    <TextField
      name={name}
      defaultValue={defaultValue}
      inputRef={ref}
      variant='standard'
      placeholder={placeholder}
      multiline={isMultiline}
      onChange={handleInputChange}
      error={Boolean(formikMeta.error)}
      helperText={formikMeta.error}
      InputProps={{
        readOnly: isDisabled ? true : false,
      }}
    />
  );
});

export default TextInputComponent;

TextInputComponent.defaultProps = {
  isDisabled: false,
  defaultValue: null,
};

TextInputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  answerType: PropTypes.string.isRequired,
  formikProps: PropTypes.object.isRequired,
  formikMeta: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};