import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Typography,
  Skeleton,
  Stack
} from '@mui/material';
import ProxyUserQuestionContainer from './ProxyUserQuestionContainer';
import { proxyQuestionsContainerStyles } from '../../styles/proxyPageStyles';
import { getCategoriesAndQuestions } from '../../server/proxyAPI';

const SkeletonContainer = () => {
  return (
    <>
      {Array(7).fill('').map((_, i) =>
        <Stack key={i} sx={{ width: { xs: '100%', md: '50%' }, mb: 2 }}>
          <Skeleton variant='text' animation='wave' />
          <Skeleton variant='text' animation='wave' width='50%' />
        </Stack>
      )}
    </>
  );
}

const ProxyQuestionsContainer = () => {

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [categoryQuestions, setCategoryQuestions] = useState([])

  useEffect(() => {
    getCategoriesAndQuestions()
    .then(({data}) => {
      setCategoryQuestions(data.sort((a, b) => a.code.localeCompare(b.code)))
      setTimeout(() => {
        setIsLoadingQuestions(false)
      }, 1500);
    })
    .catch(err => {
      console.error(err)
    })
  }, []);

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant='h5' textAlign='center' gutterBottom sx={proxyQuestionsContainerStyles.title}>
        Proxy Form
      </Typography>
      <Paper sx={proxyQuestionsContainerStyles.paper}>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          This form needs to be filled out by all legal owners/company of the mentioned lot.
        </Typography>
        <Typography variant='body1' gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
          此表格需要由上述地段的所有合法所有人/公司填写。
        </Typography>
        <Stack direction='row'>
          <Box sx={proxyQuestionsContainerStyles.colorBox} />
          <Stack sx={{ width: '100%' }}>
            {isLoadingQuestions ?
              <SkeletonContainer /> :
              <ProxyUserQuestionContainer categoryQuestions={categoryQuestions} />
            }
          </Stack>
        </Stack>
        <Typography variant='body2' gutterBottom sx={{ mt: 3, fontWeight: 'bold' }}>
          Disclaimer: Google translate has been used for the above Chinese translation. Ironfish Real Estate Melbourne apologise in advance for any incorrect translation.
        </Typography>
        <Typography variant='body2' gutterBottom sx={{  fontWeight: 'bold' }}>
          免责声明：以上中文翻译已使用谷歌翻译。 Ironfish Real Estate Melbourne 提前为任何不正确的翻译道歉。
        </Typography>
      </Paper>
    </Container>
  );
};

export default ProxyQuestionsContainer;