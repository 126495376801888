import React, { useEffect, useState } from 'react';
import {
  Stack,
  Select,
  MenuItem,
  Typography,
  FormControl,
  FormHelperText
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getProxyUsers } from '../server/proxyAPI';
import { DEFAULT_QUESTIONS } from '../constants/ProxyQuestionConstants';
import { Field } from 'formik';

const PROXY_COUNT_LIMIT = 26;

const ProxyAuthUserSelector = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [proxyUsers, setProxyUsers] = useState([]);
  const [selectedProxyUser, setSelectedProxyUser] = useState('');

  useEffect(() => {
    getProxyUsers()
    .then((response) => {
      if (response.success) {
        setProxyUsers(response.data);
      } else {
        throw 'Error retrieving proxy users'
      }
    })
    .catch(err => enqueueSnackbar(err.toString(), { variant: 'error' }))
  }, [])
  

  const handleProxyUserChange = (e, form) => {
    const { value } = e.target;
    form.setFieldValue(DEFAULT_QUESTIONS.AUTH_NAME.id, value);
    form.setFieldError(DEFAULT_QUESTIONS.AUTH_NAME.id, undefined)
    setSelectedProxyUser(value);
  }

  return (
    <Stack sx={{ width: { xs: '100%', md: '45%' }, mb: 2 }}>
      <Typography variant='body2' sx={{ width: '100%', mb: -1 }}>
        {DEFAULT_QUESTIONS.AUTH_NAME.question}
        <span style={{ color: '#d32f2f' }}> *</span>
      </Typography>
      <Field name={DEFAULT_QUESTIONS.AUTH_NAME.id} >
        {({ form, meta }) => {
          return (
            <>
              <Select
                variant='standard'
                sx={{ p: 1 }}
                fullWidth
                displayEmpty
                error={Boolean(meta.error)}
                label={DEFAULT_QUESTIONS.AUTH_ADDRESS.question}
                value={selectedProxyUser}
                onChange={(e) => handleProxyUserChange(e, form)}
              >
                <MenuItem disabled value="">
                  <em>Select proxy user</em>
                </MenuItem>
                {proxyUsers.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                    disabled={user.count >= PROXY_COUNT_LIMIT}
                  >
                    {user.name} of Ironfish
                  </MenuItem>
                ))}
              </Select>
              {meta.error && <FormHelperText sx={{color: '#d32f2f'}}>{meta.error}</FormHelperText>}
            </>
          )
        }}
      </Field>
    </Stack>
  );
};

export default ProxyAuthUserSelector;
