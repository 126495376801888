export const proxyRulesContainerStyles = {
  paper: {
    p: 5,
    pt: 1,
    borderColor: '#fec901',
    borderWidth: 2,
    borderStyle: 'solid'
  },
  collapseBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collapseLanguageStack:{
    display: 'flex',
    justifyContent: 'flex-end'
  },
};

export const proxyQuestionsContainerStyles = {
  title: {
    mb: 3,
    fontWeight: 'bold'
  },
  paper: {
    p: 2
  },
  colorBox: {
    backgroundColor: 'primary.main',
    width: '2%', mr: 3
  },
};

export const ProxyUserQuestionContainerStyles = {
  categoryLabel: {
    fontWeight: '600',
    mt: 3
  }
}
