import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import { ANSWER_TYPE } from '../../constants/ProxyQuestionConstants';
import TextInputComponent from '../inputComponents/TextInputComponent';
import DateInputComponent from '../inputComponents/DateInputComponent';

const QuestionInputComponent = React.forwardRef((props, ref) => {
  const {
    id,
    answerType,
    isRequired,
    isDisabled,
    placeholder,
    questionLabel,
    secondaryLabel,
    defaultValue,
  } = props;

  const componentMapping = {
    [ANSWER_TYPE.TEXT]: TextInputComponent,
    [ANSWER_TYPE.DATE]: DateInputComponent,
    [ANSWER_TYPE.LONG_TEXT]: TextInputComponent,
  };

  const Component = componentMapping[answerType];

  return (
    <Stack sx={{ width: { xs: '100%', md: '45%' }, mb: 2 }}>
      <Typography gutterBottom={!secondaryLabel} variant='body2' sx={{ width: '100%' }}>
        {questionLabel}
        {isRequired && <span style={{ color: '#d32f2f' }}> *</span>}
      </Typography>
      {secondaryLabel && <Typography gutterBottom color='secondary' variant='body2' sx={{ width: '100%' }}>
        {secondaryLabel}
      </Typography>}
      {Component && <Field name={id} >
        {({ field, form, meta }) => {
          return (
            <Component
              {...field}
              formikProps={form}
              formikMeta={meta}
              ref={ref}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isRequired={isRequired}
              answerType={answerType}
              isDisabled={isDisabled}
            />
          )
        }}
      </Field>}
    </Stack>
  );
});

export default QuestionInputComponent;

QuestionInputComponent.defaultProps = {
  isRequired: false,
  placeholder: 'Enter value',
  secondaryLabel: null,
  isDisabled: false,
  defaultValue: null,
};

QuestionInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  questionLabel: PropTypes.string.isRequired,
  answerType: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  secondaryLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
