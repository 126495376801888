import { fetchClient } from './fetchClient';

const getCategoriesAndQuestions = async () => {
  try {
    const response = await fetchClient('/v1/categories');
    return response;
  } catch (error) {
    return error;
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const submitProxy = async (formData, img) => {
  const submitFormData = new FormData();
  submitFormData.append('formData', JSON.stringify(formData));
  submitFormData.append('sign', dataURLtoBlob(img), 'sign.png');
  try {
    const response = await fetchClient('/v1/proxy-form', {
      body: submitFormData,
      customConfig: {
        deleteHeaders: true
      }
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getProxyUsers = async () => {
  try {
    const response = await fetchClient('/v1/proxy-form/auth-users');
    return response;
  } catch (error) {
    return error;
  }
}

export { getCategoriesAndQuestions, submitProxy, getProxyUsers };
