import { fetchClient } from './fetchClient';

const login = async (userDetails) => {
  try {
    const response = await fetchClient('/v1/auth/login',
      {
        body: JSON.stringify(userDetails)
      });
    return response;
  } catch (error) {
    return error;
  }
};

const loginAssert = async () => {
  try {
    const response = await fetchClient('/v1/auth/assert', {}, true);
    return response;
  } catch (error) {
    return error;
  }
};

export { login, loginAssert };
