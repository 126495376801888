import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Stack, IconButton } from '@mui/material';
import { OpenInFullOutlined, FileDownloadOutlined, DeleteOutlined } from '@mui/icons-material';
import { AuthContext } from '../../contexts/AuthContext';
import { ADMIN_USER_TYPE } from '../../constants/AdminConstants';
import { AdminContext } from '../../contexts/AdminContext';

const AdminTableActionCell = ({rowData}) => {
  const { authState } = useContext(AuthContext);
  const { openProxyModal, downloadProxyPDF, setSingleDeleteDialogState } = useContext(AdminContext);
  const { type } = authState;

  const isDisabled = type !== ADMIN_USER_TYPE.ADMIN

  const handleProxyModalOpen = () => {
    openProxyModal(rowData);
  };

  const handleDownloadProxyForm = () => {
    downloadProxyPDF(rowData.id);
  };

  const handleDeleteSingle = () => {
    setSingleDeleteDialogState(true, rowData);
  };

  return (
    <Stack direction='row' justifyContent='center'>
      <IconButton size='small' color='primary' onClick={handleProxyModalOpen}>
        <OpenInFullOutlined />
      </IconButton>
      <span title={isDisabled ? 'Only admin users can download' : ''}>
        <IconButton disabled={isDisabled} size='small' color='primary' onClick={handleDownloadProxyForm}>
          <FileDownloadOutlined />
        </IconButton>
      </span>
      <span title={isDisabled ? 'Only admin users can delete' : ''}>
        <IconButton disabled={isDisabled} size='small' color='primary' onClick={handleDeleteSingle}>
          <DeleteOutlined />
        </IconButton>
      </span>
    </Stack>
  );
};

export default AdminTableActionCell;

AdminTableActionCell.propTypes = {
  rowData: PropTypes.object.isRequired,
};
