import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { appBarHeaderStyles } from '../styles/appbarHeaderStyles';

const AppBarNavButton = (props) => {
  const { title, navLink } = props;

  const [isMouseOver, setIsMouseOver] = useState(false);

  const renderButtonStyles = () => {
    const buttonStyle = appBarHeaderStyles.appBarNavBtn;
    if (isMouseOver) {
      return { ...buttonStyle, borderBottom: '1px solid #fff' }
    }
    return { ...buttonStyle, borderBottom: '1px solid', borderColor: 'transparent' };
  }


  return (
    <Button
      to={navLink}
      component={Link}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      sx={renderButtonStyles()}
    >
      {title}
    </Button>
  );
};

export default AppBarNavButton;

AppBarNavButton.propTypes = {
  title: PropTypes.string.isRequired,
  navLink: PropTypes.string.isRequired,
};
