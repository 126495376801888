import React, { useState, useContext } from 'react';
import { AdminContext } from '../contexts/AdminContext';
import { Paper } from '@mui/material';
import { isWithinInterval, set } from 'date-fns';
import AdminTable from '../components/AdminTable/AdminTable';
import AdminTableHeaderFilterCard from '../components/AdminTable/AdminTableHeaderFilterCard';
import ProxyViewModal from '../components/ProxyViewModal/ProxyViewModal';
import TableLoader from '../components/AdminTable/TableLoader';
import DownloadModal from '../components/DialogModals/DownloadModal';
import { ANSWER_TYPE } from '../constants/ProxyQuestionConstants';
import AdminTablePageControlsContainer from '../components/AdminTable/AdminTablePageControlsContainer';
import AdminTableAllDownloadContainer from '../components/AdminTable/AdminTableAllDownloadContainer';
import ProxySingleDeleteModal from '../components/DialogModals/ProxySingleDeleteModal';

const AdminHome = () => {

  const { isAdminDataLoading, proxyTableData, isDownloadProcessing } = useContext(AdminContext);

  const [filterParam, setFilterParam] = useState(null); 

  const handleUpdateFilterParam = (filterParam) => {
    setFilterParam(filterParam)
  }

  const filteredAdminData = () => {
    if (proxyTableData && filterParam) {
      const { queryType, searchQuery, dateRange } = filterParam;
      let cleanQuery;
      if (['proxyPerson', 'expiryDate'].includes(queryType)) {
        cleanQuery = searchQuery;
      } else {
        cleanQuery = searchQuery
        .trim()
        .replace(/[\\*)(\][?+]/g, '')
        .toLowerCase();
      };

      const searchFiltered = [];
      if (queryType === 'proxyPerson') {
        proxyTableData.forEach((proxy) => {
          if (proxy.proxyPerson[0].id === searchQuery) {
            searchFiltered.push(proxy)
          }
        });
      } else if (queryType === 'expiryDate') {
        proxyTableData.forEach((proxy) => {
          const foundDateAnswer = proxy.QuestionAnswers.find((question) => question.question_template.answerDataType === ANSWER_TYPE.DATE);
          if (foundDateAnswer) {
            // Reset answer time to 0
            const formattedDate = set(new Date(foundDateAnswer.answer), { hours: 0, minutes: 0, seconds: 0 });
            // Check the the answer is within the given interval
            const isDateWithinInterval = isWithinInterval(
              formattedDate, { start: new Date(searchQuery[0]), end: new Date(searchQuery[1]) });
            if (isDateWithinInterval) {
              searchFiltered.push(proxy);
            }
          }
        });
      } else if (queryType === 'id') {
        proxyTableData.forEach((proxy) => {
          if (proxy.id === searchQuery) {
            searchFiltered.push(proxy)
          }
        });
      } else {
        const queryTypeMap = {
          'lotOwner': 'name',
          'ProxyLots': 'lotId',
        };
        proxyTableData.forEach((proxy) => {
          if (proxy[queryType].some((item) => item[queryTypeMap[queryType]].toLowerCase().includes(cleanQuery))) {
            searchFiltered.push(proxy)
          }
        });
      }

      if (dateRange[0] !== null && dateRange[1] !== null) {
        const dataToQuery = searchQuery !== '' ? searchFiltered : proxyTableData;
        return dataToQuery.filter((item) => {
          const formattedDate = set(new Date(item.createdAt), {hours: 0, minutes: 0, seconds: 0});
          return isWithinInterval(
            formattedDate, { start: dateRange[0], end: dateRange[1] })
        });
      }

      return searchFiltered;
    }
    return proxyTableData;
  };

  const renderTableView = () => {
    if (isAdminDataLoading) {
      return <TableLoader />;
    }
    return (
      <>
        <AdminTablePageControlsContainer>
          <>
            <AdminTableHeaderFilterCard handleUpdateFilterParam={handleUpdateFilterParam} />
            <AdminTableAllDownloadContainer/>
          </>
        </AdminTablePageControlsContainer>
        <AdminTable tableData={filteredAdminData()} />
        <ProxyViewModal />
        <DownloadModal visibility={isDownloadProcessing}/>
        <ProxySingleDeleteModal />
      </>
    );
  };

  return (
    <Paper sx={{ mt: 3, p: 2 }}>
      {renderTableView()}
    </Paper>
  );
};

export default AdminHome;
