const baseURL = process.env.REACT_APP_BASE_URL;

const getToken = () => {
  return new Promise((resolve, reject) => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    if (authData?.token) {
      resolve(authData.token);
    } else {
      reject('Unauthorized');
    }
  });
};

async function fetchClient(endpoint, { body, customConfig } = {}, isProtected) {
  let headers = { 'Content-Type': 'application/json' }
  if (isProtected) {
    try {
      const token = await getToken();
      headers.Authorization = `${token}`;
    } catch (error) {
      return Promise.reject(error); // TODO: handle error
    }
  };

  const config = {
    method: body ? 'POST' : 'GET',
    headers: {
      ...headers,
    },
  };

  if (body) {
    config.body = body;
    if (customConfig?.deleteHeaders) {
      delete config.headers['Content-Type']
    } 
  };

  if (customConfig?.method) {
    config.method = customConfig?.method;
  };

  return fetch(`${baseURL}${endpoint}`, config)
    .then(async response => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        let error;
        if (data.message) {
          error = data.message;
        } else {
          error = data.error;
        }
        return Promise.reject(error) // TODO: handle error
      }
    });
};

//TODO: Fix this with a generic method
async function downloadClient(endpoint) {
  let headers = { 'Content-Type': 'application/pdf' }
  try {
    const token = await getToken();
    headers.Authorization = `${token}`;
  } catch (error) {
    return Promise.reject(error); // TODO: handle error
  }

  const config = {
    method: 'GET',
    headers: {
      ...headers
    }
  };

  return fetch(`${baseURL}${endpoint}`, config)
    .then(async response => {
      const data = await response.blob();
      if (response.ok) {
        let serverFileName = response.headers.get('Content-Disposition').split('filename=')[1].trim();
        let file = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = file;
        serverFileName  = serverFileName.replace('"',''); // replacing one " charcter
        serverFileName = serverFileName.replace('"', ''); // replacing second " character
        link.download = serverFileName;
        link.click();
        window.URL.revokeObjectURL(file);
        link.remove();
        return response;
      } else {
        let error;
        if (data.message) {
          error = data.message;
        } else {
          error = data.error;
        }
        return Promise.reject(error) // TODO: handle error
      }
    });
};

export { fetchClient, downloadClient};