import React, { useContext, useState } from 'react';
import { Divider, Typography, CardContent, Stack, Button } from '@mui/material';
import DownloadAllModal from '../DialogModals/DownloadAllModal';
import { AuthContext } from '../../contexts/AuthContext';
import { ADMIN_USER_TYPE } from '../../constants/AdminConstants';
import ProxyAllDeleteModal from "../DialogModals/ProxyAllDeleteModal";
import {AdminContext} from "../../contexts/AdminContext";

const initialDownloadDialogState = {
  visibility: false,
  downloadType: null,
}

const AdminTableAllDownloadContainer = () => {

  const { openDeleteAllProxyDialog } = useContext(AdminContext);
  const { authState } = useContext(AuthContext);
  const { type } = authState;

  const isDisabled = type !== ADMIN_USER_TYPE.ADMIN

  const [downloadDialogState, setDownloadDialogState] = useState(initialDownloadDialogState);

  const handleDownloadSelect = (type) => {
    setDownloadDialogState({
      visibility: true,
      downloadType: type,
    });
  };

  return (
    <>
      <CardContent sx={{ width: '80%', mt: -1 }}>
        <Divider sx={{ mb: 1 }} />
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography noWrap variant='body2'>Download All as</Typography>
            <span title={isDisabled ? 'Only admin users can download' : ''}>
            <Button variant='contained' onClick={() => handleDownloadSelect('csv')} disabled={isDisabled}>CSV</Button>
          </span>
            <span title={isDisabled ? 'Only admin users can download' : ''}>
            <Button variant='contained' onClick={() => handleDownloadSelect('pdf')} disabled={isDisabled}>PDF</Button>
          </span>
          </Stack>
          <span title={isDisabled ? 'Only admin users can delete records' : ''}>
            <Button variant='contained' onClick={openDeleteAllProxyDialog} disabled={isDisabled}>Delete All</Button>
          </span>
        </Stack>
      </CardContent>
      <DownloadAllModal downloadDialogState={downloadDialogState} setDownloadDialogState={setDownloadDialogState} />
      <ProxyAllDeleteModal />
    </>
  );
};

export default AdminTableAllDownloadContainer;
