import React from 'react';
import {
  Fade,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { isAfter } from 'date-fns/esm';
import AdminTableActionCell from './AdminTableActionCell';
import { ANSWER_TYPE } from '../../constants/ProxyQuestionConstants';

const defaultCellStyle = {
  textAlign: 'center',
  py: 1,
  backgroundColor: 'white',
  border: '0.5px solid rgba(224, 224, 224, 1)'
}

const AdminTableBody = (props) => {
  const { tableData } = props;

  const renderData = (data) => {
    if (data) {
      return data;
    }
    return '- N/A -';
  };

  const renderExpiryDateOrDescription = (rowData, column) => {
    if (column === 'expiry') {
      const foundDateAnswer = rowData.find((item) => item.question_template.answerDataType === ANSWER_TYPE.DATE)
      if (foundDateAnswer) {
        return format(new Date(foundDateAnswer.answer), 'dd-MM-yyyy');
      }
    } else if (column === 'description') {
      const foundStringAnswer = rowData.find((item) => item.question_template.answerDataType === ANSWER_TYPE.LONG_TEXT)
      if (foundStringAnswer) {
        return (
          <Tooltip
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={renderData(foundStringAnswer.answer)}>
            <Typography noWrap>{renderData(foundStringAnswer.answer)}</Typography>
          </Tooltip>
        );
      };
    };
    return '- N/A -';
  };

  const handleDateCellRender = (cellValue) => {
    const cellData = new Date(cellValue)
    if (cellData instanceof Date && !isNaN(cellData)) {
      return format(new Date(cellValue), 'dd-MM-yyyy');
    }
    return '- N/A -';
  };

  const renderSubmitId = (submitId) => {
    return (
      <Tooltip
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={submitId}>
        <Typography noWrap>{submitId}</Typography>
      </Tooltip>
    );
  };

  const renderCellStyle = (rowData) => {
    let cellStyle = {...defaultCellStyle};
    const foundDateAnswer = rowData.find((item) => item.question_template.answerDataType === ANSWER_TYPE.DATE)
    if (foundDateAnswer && isAfter(new Date(), new Date(foundDateAnswer.answer))) {
      return {...cellStyle, backgroundColor: '#ffa4a2'}
    }
    return cellStyle;
  };

  const renderTableData = () => {
    return (
      <TableBody>
        {tableData.map((row, index) => {
          const lotIds = row.ProxyLots.flatMap((proxyLot) => proxyLot.lotId).join(', ');
          return (
            <TableRow key={index}>
              <TableCell sx={{ ...renderCellStyle(row.QuestionAnswers), flexWrap: 'nowrap', position: 'sticky', left: 0 }}>{renderData(lotIds)}</TableCell>
              <TableCell sx={renderCellStyle(row.QuestionAnswers)}>{renderData(row.lotOwner[0].name)}</TableCell>
              <TableCell sx={renderCellStyle(row.QuestionAnswers)}>{renderData(row.proxyPerson[0].name)}</TableCell>
              <TableCell sx={renderCellStyle(row.QuestionAnswers)}>{renderData(row.email)}</TableCell>
              <TableCell sx={renderCellStyle(row.QuestionAnswers)}>{renderExpiryDateOrDescription(row.QuestionAnswers, 'expiry')}</TableCell>
              <TableCell sx={{...renderCellStyle(row.QuestionAnswers), maxWidth: 240}}>{renderExpiryDateOrDescription(row.QuestionAnswers, 'description')}</TableCell>
              <TableCell sx={renderCellStyle(row.QuestionAnswers)}>{handleDateCellRender(row.createdAt)}</TableCell>
              <TableCell sx={{...renderCellStyle(row.QuestionAnswers), maxWidth: 240}}>{renderSubmitId(row.id)}</TableCell>
              <TableCell sx={{...defaultCellStyle, position: 'sticky', right: 0, mr: 0 }}><AdminTableActionCell rowData={row} /></TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    );
  };

  const renderEmptyDataMessage = () => {
    return (
      <tbody>
        <tr>
          <td colSpan={8}>
            <Typography textAlign='center' sx={{ p: 2 }}>No data to display!</Typography>
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <>
      {tableData.length > 0 ? renderTableData() : renderEmptyDataMessage()}
    </>
  );
};

export default AdminTableBody;

AdminTableBody.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
