import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {allProxyDelete, downloadProxyForm, getProxiesForms} from '../server/adminAPI';
import { getCategoriesAndQuestions, getProxyUsers } from '../server/proxyAPI';

const AdminContext = createContext();

const proxyModalInitialState = {
  visible: false,
  modalData: null
};

const AdminContextProvider = (props) => {

  const { enqueueSnackbar } = useSnackbar();

  const [proxyModalState, setProxyModalState] = useState(proxyModalInitialState);
  const [isAdminDataLoading, setIsAdminDataLoading] = useState(true);
  const [proxyTableData, setProxyTableData] = useState([]);
  const [questionsAndCategories, setQuestionsAndCategories] = useState([]);
  const [isDownloadProcessing, setIsDownloadProcessing] = useState(false);
  const [proxyUsers, setProxyUsers] = useState([]);
  const [singleDeleteModalState, setSingleDeleteModalState] = useState({visibility: false, isDeleting: false, row: {}});
  const [allDeleteModalState, setAllDeleteModalState] = useState({visibility: false, isDeleting: false});

    useEffect(() => {
    Promise.all([
      getProxiesForms(),
      getCategoriesAndQuestions(),
      getProxyUsers(),
    ])
      .then((response) => {
        if (response[0].success) {
          setProxyTableData(response[0].data.items);
        } else {
          throw new Error('Failed retrieving proxy data');
        }

        if (response[1].success) {
          setQuestionsAndCategories(response[1].data);
        } else {
          throw new Error('Failed retrieving questions');
        }

        if (response[2].success) {
          setProxyUsers(response[2].data);
        } else {
          throw new Error('Failed retrieving proxy users');
        }

        setIsAdminDataLoading(false);
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: 'error' }))
  }, [enqueueSnackbar])

  const downloadProxyPDF = (proxyId) => {
    setIsDownloadProcessing(true);
    downloadProxyForm(proxyId)
    .then((response) => {
      if (response.ok && response.status === 200) {
        setIsDownloadProcessing(false);
      }
    })
    .catch((error)=> {
      setIsDownloadProcessing(false);
      enqueueSnackbar(error.toString(), { variant: 'error' })
    })
  };

  const openProxyModal = (rowData = {}) => {
    setProxyModalState({
      visible: true,
      modalData: rowData
    });
  };

  const closeProxyModal = () => {
    setProxyModalState(proxyModalInitialState);
  };

  const loadProxyListData = () => {
    getProxiesForms()
      .then((response) => {
        if (response.success) {
          setProxyTableData(response.data.items);
        } else {
          throw new Error('Failed retrieving proxy data');
        }
        setIsAdminDataLoading(false);
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: 'error' }))
  }

  const setSingleDeleteDialogState = (visibility, row = {}) => {
    setSingleDeleteModalState((prevState) => ({
      ...prevState,
      row: row,
      visibility: visibility
    }));
  };

  const deleteSingleProxy = (id) => {
    setSingleDeleteModalState((prevState) => ({
      ...prevState,
      isDeleting: true,
    }));
    setIsAdminDataLoading(true);

    allProxyDelete([id])
      .then((response) => {
        if (response.success) {
          setSingleDeleteModalState({ visibility: false, isDeleting: false, row: {} });
          setIsAdminDataLoading(false);
          loadProxyListData();
        } else {
          throw new Error(response);
        }
      })
      .catch((err) => {
        setSingleDeleteModalState({ visibility: false, isDeleting: false, row: {} });
        setIsAdminDataLoading(false);
        enqueueSnackbar(err.toString(), { variant: 'error' });
      })
  };

  const openDeleteAllProxyDialog = () => {
      setAllDeleteModalState((prevState) => ({
          ...prevState,
          visibility: true,
      }));
  };

  const closeDeleteAllProxyDialog = () => {
      setAllDeleteModalState({
          visibility: false,
          isDeleting: false,
      });
  };

  const deleteAllProxy = () => {
      setAllDeleteModalState((prevState) => ({
          ...prevState,
          isDeleting: true,
      }))
      setIsAdminDataLoading(true);

      const proxyIds = proxyTableData.map((proxy) => proxy.id);
      allProxyDelete(proxyIds)
          .then((response) => {
              if (response.success) {
                  setAllDeleteModalState({ visibility: false, isDeleting: false });
                  setIsAdminDataLoading(false);
                  loadProxyListData();
              } else {
                  throw new Error(response);
              }
          })
          .catch((err) => {
              setAllDeleteModalState({ visibility: false, isDeleting: false });
              setIsAdminDataLoading(false);
              enqueueSnackbar(err.toString(), { variant: 'error' });
          })
  };

  return (
    <AdminContext.Provider
      value={{
        openProxyModal,
        closeProxyModal,
        proxyModalState,
        setProxyModalState,
        isAdminDataLoading,
        proxyTableData,
        questionsAndCategories,
        downloadProxyPDF,
        isDownloadProcessing,
        proxyUsers,
        singleDeleteModalState,
        setSingleDeleteDialogState,
        deleteSingleProxy,
        allDeleteModalState,
        openDeleteAllProxyDialog,
        closeDeleteAllProxyDialog,
        deleteAllProxy,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export { AdminContext, AdminContextProvider };

AdminContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
