import React, { useContext } from 'react';
import {
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { Clear, FileDownloadOutlined } from '@mui/icons-material';
import { AdminContext } from '../../contexts/AdminContext';
import { AuthContext } from '../../contexts/AuthContext';
import { ADMIN_USER_TYPE } from '../../constants/AdminConstants';

const ProxyViewModalTopBtnContainer = () => {

  const { closeProxyModal, proxyModalState, downloadProxyPDF } = useContext(AdminContext);
  const { authState } = useContext(AuthContext);
  const { type } = authState;

  const isDisabled = type !== ADMIN_USER_TYPE.ADMIN;

  const handleDownload = () => {
    const { id } = proxyModalState.modalData;
    downloadProxyPDF(id);
  };

  const renderDownloadButton = () => {
    if (isDisabled) {
      return (
        <span title={isDisabled ? 'Only admin users can download' : ''}>
          <IconButton disabled={isDisabled}>
            <FileDownloadOutlined />
          </IconButton>
        </span>
      );
    }

    return (
      <Button
        variant='outlined'
        color='primary'
        sx={{ mr: 2 }}
        onClick={handleDownload}
        startIcon={<FileDownloadOutlined />}
      >
        Download
      </Button>
    );
  };

  return (
    <Stack direction='row' sx={{ display: 'flex' }} justifyContent='end'>
      {renderDownloadButton()}
      <IconButton onClick={closeProxyModal} color='primary'>
        <Clear />
      </IconButton>
    </Stack>
  );
};

export default ProxyViewModalTopBtnContainer;
