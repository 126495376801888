import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

const ProxyDeleteAllConfirmationDialog = ({ visibility, handleClose, onDeleteConfirm }) => {

    return (
        <Dialog
            open={visibility}
            onClose={handleClose}
        >
            <DialogTitle sx={{fontWeight: 'bold'}}>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure want to delete all proxy records? </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='warning' onClick={onDeleteConfirm}>Confirm Delete</Button>
                <Button variant='contained' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

ProxyDeleteAllConfirmationDialog.propTypes = {
    visibility: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onDeleteConfirm: PropTypes.func.isRequired,
};

export default ProxyDeleteAllConfirmationDialog;
