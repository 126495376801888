import React, { useContext } from 'react';
import {
  Modal,
  Box,
  Typography,
  Fade,
} from '@mui/material';
import { AdminContext } from '../../contexts/AdminContext';
import ProxyViewModalContent from './ProxyViewModalContent';
import ProxyViewModalTopBtnContainer from './ProxyViewModalTopBtnContainer';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '90vh',
  bgcolor: 'background.paper',
  p: 4,
  overflow: 'scroll',
  overflowX: 'hidden'
};

const ProxyViewModal = () => {
  const { proxyModalState, closeProxyModal } = useContext(AdminContext);

  return (
    <Modal
      open={proxyModalState.visible}
      onClose={closeProxyModal}
    >
      <Fade in={proxyModalState.visible}>
        <Box sx={modalStyle}>
          <ProxyViewModalTopBtnContainer />
          <Typography variant='h5' textAlign='center' gutterBottom sx={{ fontWeight: 'bold' }}>
            Proxy Form
          </Typography>
          <ProxyViewModalContent />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProxyViewModal;