import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Stack,
  DialogContentText
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { subMonths } from 'date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import DownloadModal from './DownloadModal';
import {downloadAllCSV, downloadAllPDF} from '../../server/adminAPI';

const DownloadAllModal = (props) => {
  const { downloadDialogState, setDownloadDialogState } = props;
  const { visibility, downloadType } = downloadDialogState;
  const isCSVType = downloadType === 'csv';
  const todayDate = new Date();

  const { enqueueSnackbar } = useSnackbar();

  const [dateRangeValue, setDateRangeValue] = useState([subMonths(todayDate, 6), todayDate]);
  const [showDownloading, setShowDownloading] = useState(false)

  const handleClose = () => {
    setDownloadDialogState({ visibility: false, downloadType: null });
  };

  const downloadCSV = (from, to) => {
    downloadAllCSV(from, to)
        .then((res) => {
          if (res.ok) {
            setShowDownloading(false);
            handleClose();
          } else {
            throw 'Something went wrong'
          }
        })
        .catch((error) => {
          setShowDownloading(false);
          enqueueSnackbar(error.toString(), { variant: 'error' })
        });
  };

  const downloadPDF = (from, to) => {
    downloadAllPDF(from, to)
        .then((res) => {
          if (res.ok) {
            setShowDownloading(false);
            handleClose();
          } else {
            throw 'Something went wrong'
          }
        })
        .catch((error) => {
          setShowDownloading(false);
          enqueueSnackbar(error.toString(), { variant: 'error' })
        });
  };

  const onDownloadClick = () => {
    setShowDownloading(true);

    const fromDate = new Date(dateRangeValue[0]).toISOString();
    const toDate = new Date(dateRangeValue[1]).toISOString();
    isCSVType ? downloadCSV(fromDate,  toDate) : downloadPDF(fromDate,  toDate);
  };

  return (
    <Dialog
      open={visibility}
      onClose={handleClose}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Download Table Records as {isCSVType ? 'CSV' : 'PDF'}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>Please select submit date range to download</DialogContentText>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            sx={{ flex: 1 }}
            startText="From"
            endText="To"
            value={dateRangeValue}
            onChange={(newValue) => {
              setDateRangeValue(newValue);
            }}
            inputFormat='dd-MM-yyyy'
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size='small' />
                <Box sx={{ mx: 1 }}></Box>
                <TextField {...endProps} size='small' />
              </>
            )}
          />
        </LocalizationProvider>
        <Stack direction='row' sx={{ mt: 2 }} spacing={2} justifyContent='flex-end'>
          <Button
            variant='outlined'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={onDownloadClick}
            disabled={!dateRangeValue[0] && !dateRangeValue[1]}
          >
            Download
          </Button>
        </Stack>
      </DialogContent>
      <DownloadModal visibility={showDownloading} />
    </Dialog>
  );
};

export default DownloadAllModal;

DownloadAllModal.propTypes = {
  downloadDialogState: PropTypes.object.isRequired,
  setDownloadDialogState: PropTypes.func.isRequired,
};
