import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Box, IconButton, Typography, Stack, Checkbox, Divider } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import {Clear} from '@mui/icons-material';
import { REQUIRED_FIELD_MSG } from '../../constants/CommonConstants';
import { QUESTION_ID_MAP } from '../../constants/ProxyQuestionConstants';

const SignatureComponent = React.forwardRef((props, ref) => {
  const { showEmptySignError, setShowEmptySignError } = props;

  const [isDrawn, setIsDrawn] = useState(false);
  const [sigPadWidth, setSigPadWidth] = useState(window.innerWidth * 0.4);
  const [isSignConsentChecked, setIsSignConsentChecked] = useState(false);
  const [isShowConsentError, setIsShowConsentError] = useState(false);

  const onResizeChange = () => {
    if (window.innerWidth > 1200) {
      setSigPadWidth(window.innerWidth * 0.5);
      return;
    } else if (window.innerWidth > 992) {
      setSigPadWidth(window.innerWidth * 0.6);
      return;
    }
    setSigPadWidth(window.innerWidth * 0.7)
    return;
  }

  const signatureWrapperClick = () => {
    if (!isSignConsentChecked && !isShowConsentError) {
      setIsShowConsentError(true);
    };
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeChange);
    ref.current.off();
    return () => {
      window.removeEventListener('resize', onResizeChange);
    }
  }, [ref]);

  const setSignatureDrawn = () => {
    if (!isDrawn) {
      setIsDrawn(true);
    }
  };

  const handleClearSignError = () => {
    showEmptySignError && setShowEmptySignError(false);
  };

  const handleClearSignaturePad = () => {
    ref.current.clear();
    setIsDrawn(false);
  };

  const getSigPadHeight = () => {
    if (sigPadWidth < 540) {
      return sigPadWidth/2;
    } else if (sigPadWidth < 300) {
      return sigPadWidth
    }
    return sigPadWidth/3;
  }

  const handleSignConsentChange = (e, formik) => {
    const { checked } = e.target;
    setIsSignConsentChecked(checked);
    if (checked) {
      ref.current.on();
      setIsShowConsentError(false);
      formik.setFieldValue(QUESTION_ID_MAP.SIGN_CONSENT, true);
      formik.setFieldError(QUESTION_ID_MAP.SIGN_CONSENT, undefined);
    } else {
      ref.current.off();
      handleClearSignaturePad();
      formik.setFieldValue(QUESTION_ID_MAP.SIGN_CONSENT, undefined);
    }
  };

  return (
    <>
      <Divider sx={{mb: 2}}/>
      <Field name={QUESTION_ID_MAP.SIGN_CONSENT}>
        {({
          field: { name },
          form,
          meta,
        }) => {
          return (
            <Stack sx={{ mb: 2 }}>
              <Stack direction='row' spacing={2}>
                <Checkbox
                  name={name}
                  checked={isSignConsentChecked}
                  onChange={(e) => handleSignConsentChange(e, form)}
                />
                <Stack>
                  <Typography variant='body2'>
                    I/We hereby acknowledge that I/We are the legal owners of the above mentioned lot numbers.
                  </Typography>
                  <Typography variant='body2'>
                    I/We also acknowledge that I/We have read, understand, and agree to the selection made.
                  </Typography>
                  {isShowConsentError && <Typography gutterBottom sx={{ fontSize: '0.75rem', color: '#d32f2f' }}>
                    Please click the acknowledgement, before signing.
                  </Typography>}
                </Stack>
              </Stack>
              {meta.error && (
                <Typography gutterBottom sx={{ fontSize: '0.75rem', color: '#d32f2f', ml: 2 }}>
                  {meta.error}
                </Typography>
              )}
            </Stack>
          )
        }}
      </Field>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ display: 'flex', flex: 1 }} spacing={2} alignItems='center'>
        <Box sx={{ position: 'relative', mb: 2, flex: 0.7 }}>
          <div onClick={signatureWrapperClick} id='sig-pad-wrapper' style={{ width: '100%', border: `2px solid ${showEmptySignError ? '#d32f2f' : '#f3f3f3'}` }}>
            <SignatureCanvas
              ref={ref}
              onBegin={handleClearSignError}
              onEnd={setSignatureDrawn}
              backgroundColor='#fff'
              clearOnResize
              canvasProps={{ width: sigPadWidth, height: getSigPadHeight() }}
            />
          </div>
          {showEmptySignError && <Typography textAlign='center' sx={{ fontSize: '0.75rem', color: '#d32f2f' }}>
            {REQUIRED_FIELD_MSG}
          </Typography>}
          {isDrawn && <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleClearSignaturePad}
          >
            <Clear />
          </IconButton>}
          {!isDrawn &&
            <Typography
              sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
              color='secondary'>
              Please sign here
            </Typography>}
        </Box>
        <Stack>
          <Typography textAlign='center' variant='caption' sx={{ flex: 0.3, fontWeight: 'bold', mb: 2 }}>
            This form needs to be signed by all legal owners including company representative (If any).
          </Typography>
          <Typography textAlign='center' variant='caption' gutterBottom sx={{ flex: 0.3, fontWeight: 'bold' }}>
            此表格需要由包括公司代表（如果有）在内的所有合法所有者签署。
          </Typography>
        </Stack>
      </Stack>
    </>
  );
});

export default SignatureComponent;

SignatureComponent.propTypes = {
  showEmptySignError: PropTypes.bool.isRequired,
  setShowEmptySignError: PropTypes.func.isRequired,
};
