import React from 'react';
import {
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';
import { TABLE_COLUMNS } from '../../constants/AdminConstants';

const AdminTableHeader = () => {

  const renderHeaderCellStyle = (index) => {
    const defaultStyle = { color: '#fff', textAlign: 'center', fontWeight: 'bold', minWidth: 200, backgroundColor: 'primary.main', };
    if (index === 0) {
      return { ...defaultStyle, borderTopLeftRadius: 10, position: 'sticky', left: 0, zIndex: 10 };
    }
    if (index === 4) { // Expiry Date
      return { ...defaultStyle, minWidth: 120 };
    }
    if (index === 6) { // Submit Date
      return { ...defaultStyle, minWidth: 120 };
    }
    if (index === TABLE_COLUMNS.length - 1) {
      return { ...defaultStyle, minWidth: 100, borderTopRightRadius: 10, position: 'sticky', right: 0 };
    }
    return defaultStyle;
  };

  return (
    <TableHead sx={{ backgroundColor: 'primary.main', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
      <TableRow>
        {TABLE_COLUMNS.map((colName, index) => (
          <TableCell key={index} sx={renderHeaderCellStyle(index)}>{colName}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AdminTableHeader;