import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from '@mui/material';
import { 
  LockOutlined,
  PersonOutline,
  VisibilityOutlined,
  VisibilityOffOutlined
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import MV_black_logo from '../images/MV_black_logo.png';
import { loginStyles } from '../styles/loginStyles';
import { AuthContext } from '../contexts/AuthContext';
import routes from '../constants/RouteConstants';
import { login } from '../server/userAPI.js';

const validationSchema = yup.object({
  username: yup
    .string('Enter your username')
    .required('Username is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

const Login = () => {

  const { handleAuthStateUpdate } = useContext(AuthContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      handleLogin(values);
    },
  });

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik]);

  const handleLogin = (values) => {
    login(values)
      .then((res) => {
        if (res.success) {
          enqueueSnackbar('Success', {
            variant: 'success',
            autoHideDuration: 1500,
            onExit: () => navigate(routes.admin)
          });
          handleAuthStateUpdate(res);
        } else {
          throw res;
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(err.toString(), { variant: 'error' } )
      })
  };

  const { values, touched, errors, handleChange, handleSubmit } = formik;

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container sx={loginStyles.container}>
      <Paper sx={loginStyles.paper}>
        <Box sx={loginStyles.titleContainerBox}>
          <img alt='logo' src={MV_black_logo} height='90' style={{ objectFit: 'contain', textAlign: 'center' }} />
          <Typography variant='h6' align='center'>Admin Login</Typography>
        </Box>
        <Box sx={loginStyles.inputContainerBox}>
          <TextField
            id='username'
            sx={{ marginBottom: 2 }}
            variant='standard'
            onChange={handleChange}
            label='Username'
            value={values.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline />
                </InputAdornment>
              ),
            }}
            error={touched.username && Boolean(errors.username)}
            helperText={touched.username && errors.username}
          />
          <TextField
            id='password'
            sx={{ marginBottom: 3 }}
            variant='standard'
            onChange={handleChange}
            label='Password'
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={handlePasswordVisibility}>
                  {showPassword ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}
                </IconButton>
              )
            }}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
        </Box>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant='contained'
          onClick={handleSubmit}
        >
          Login
        </LoadingButton>
      </Paper>
    </Container>
  );
};

export default Login;
