import React, { useState, useRef } from 'react';
import Lottie from "lottie-react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, Stack, Typography, Collapse, Button, Divider } from '@mui/material';
import successAnimation from '../../animations/success_animation.json';
import emailAnimation from '../../animations/email_animation.json';
import routes from '../../constants/RouteConstants';

const initialSubmitDialogState = {
  visibility: false,
  submitId: null,
};

const animationsArray = [successAnimation, emailAnimation];

const ProxySubmitModal = ({submitDialogState, setSubmitDialogState, email}) => {

  const lottieRef = useRef();
  const navigate = useNavigate();

  const [activeAnimationIndex, setActiveAnimationIndex] = useState(0);
  
  const onAnimationComplete = () => {
    setActiveAnimationIndex(1);
    lottieRef.current.play();
  };

  const handleOkClick = () => {
    setSubmitDialogState(initialSubmitDialogState);
    navigate(routes.landing, { replace: true })
  }

  return (
    <Dialog
      open={submitDialogState.visibility}
      onClose={handleOkClick}
      disableEscapeKeyDown
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 5 }}>
        <Lottie
          initialSegment={activeAnimationIndex === 0 && [0, 60]}
          lottieRef={lottieRef}
          animationData={animationsArray[activeAnimationIndex]}
          loop={false}
          onComplete={onAnimationComplete}
          style={{ width: 'auto', height: 150, flex: 1 }}
        />
        <Stack>
          <Typography
            fontWeight='bold'
            textAlign='center'
            variant='body1'
            color='primary.main'
          >
            Proxy form submitted successfully!
          </Typography>
          <Collapse in={activeAnimationIndex === 1}>
            <Stack>
              <Typography
                textAlign='center'
                variant='body1'
                color='primary.main'
              >
                You will receive a copy of proxy form to <span style={{ fontWeight: 'bold' }}>{email}</span> shortly.
              </Typography>
              <Divider sx={{my: 2}}/>
              <Typography
                textAlign='center'
                variant='body1'
                color='primary.main'
              >
                For enquiries please contact IronFish with following id.
              </Typography>
              <Typography
                textAlign='center'
                variant='body2'
                color='primary.main'
              >
                {submitDialogState.submitId}
              </Typography>
              <Button variant='contained' sx={{mt: 3}} onClick={handleOkClick}>
                Okay
              </Button>
            </Stack>
          </Collapse>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ProxySubmitModal;

ProxySubmitModal.propTypes = {
  submitDialogState: PropTypes.object.isRequired,
  setSubmitDialogState: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};
