import React from 'react';
import ProxyRulesContainer from '../components/ProxyQuestionsComponents/ProxyRulesContainer';
import ProxyQuestionsContainer from '../components/ProxyQuestionsComponents/ProxyQuestionsContainer';
import ProxySubmissionDurationCompleteView from "../components/ProxySubmissionDurationCompleteView";

const ProxyForm = () => {
  return (
    <>
      { false && <ProxySubmissionDurationCompleteView/> }
      <ProxyRulesContainer/> 
      <ProxyQuestionsContainer/>
    </>
  );
};

export default ProxyForm;
