import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Box,
  Stack,
  Select,
  MenuItem,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import {
  Clear,
  SearchOutlined
} from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { AdminContext } from '../../contexts/AdminContext';

const INPUT_TYPES = {
  STRING: 'string',
  DATE: 'date',
  SELECT: 'select'
}

const SEARCH_SELECTOR_ITEMS = [
  {
    name: 'ProxyLots',
    type: INPUT_TYPES.STRING,
    displayName: 'Lot Number(s)',
    placeholder: 'Enter lot numbers(s) to search',
  },
  {
    name: 'lotOwner',
    type: INPUT_TYPES.STRING,
    displayName: 'Lot Owner(s)',
    placeholder: 'Enter owners name(s) to search',
  },
  {
    name: 'proxyPerson',
    type: INPUT_TYPES.SELECT,
    displayName: 'Authorised Users',
    placeholder: '',
  },
  {
    name: 'expiryDate',
    type: INPUT_TYPES.DATE,
    displayName: 'Expiry Date',
    placeholder: '',
  },
  {
    name: 'id',
    type: INPUT_TYPES.STRING,
    displayName: 'Submit ID',
    placeholder: 'Enter Submit ID from proxy form submit',
  },
];

const DateRangePickerComp = (props) => {
  const { value, onChange } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        sx={{ flex: 1 }}
        startText="From"
        endText="To"
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        inputFormat='dd-MM-yyyy'
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} size='small' />
            <Box sx={{ mx: 1 }}></Box>
            <TextField {...endProps} size='small' />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

const AdminTableHeaderFilterCard = (props) => {
  const { handleUpdateFilterParam } = props;

  const { proxyUsers } = useContext(AdminContext);

  const [selectedSearchValue, setSelectedSearchValue] = useState(SEARCH_SELECTOR_ITEMS[0]);
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSelectorChange = (e) => {
    const { value } = e.target;
    value === 'expiryDate' ? setSearchQuery([null, null]) : setSearchQuery('');
    setSelectedSearchValue(SEARCH_SELECTOR_ITEMS.find((item) => item.name === value));
  };

  const handleProxyNameChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReset = () => {
    setSelectedSearchValue(SEARCH_SELECTOR_ITEMS[0]);
    setSearchQuery('');
    setDateRangeValue([null, null]);
    handleUpdateFilterParam(null)
  };

  const onSubmit = () => {
    handleUpdateFilterParam({
      queryType: selectedSearchValue.name,
      searchQuery,
      dateRange: dateRangeValue
    });
  }

  const renderSearchInput = () => {
    switch (selectedSearchValue.type) {
      case INPUT_TYPES.STRING:
        return (
          <TextField
            fullWidth
            size='small'
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            placeholder={selectedSearchValue.placeholder}
          />
        );
      case INPUT_TYPES.SELECT:
        return (
          <Select
            variant='standard'
            fullWidth
            displayEmpty
            sx={{ p: 1 }}
            value={searchQuery}
            onChange={handleProxyNameChange}
          >
            <MenuItem disabled value="">
              <em>Select Authorised User</em>
            </MenuItem>
            {proxyUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
            ))}
          </Select>
        );
      case INPUT_TYPES.DATE:
        return (
          <DateRangePickerComp value={searchQuery} onChange={setSearchQuery} />
        );
      default:
        break;
    }
  }

  const isDisableClear = (!dateRangeValue[0] && !dateRangeValue[1]) && (searchQuery === '' || (!searchQuery[0] && !searchQuery[1]))

  return (
    <>
      <Typography variant='h5' textAlign='center' gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
        Submitted Proxy Data
      </Typography>
      <CardContent sx={{width: '80%'}}>
        <Stack direction='row' spacing={2} >
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography noWrap variant='body2'>Search with</Typography>
            <Select
              variant='standard'
              sx={{ p: 1 }}
              value={selectedSearchValue.name}
              onChange={handleSelectorChange}
            >
              {SEARCH_SELECTOR_ITEMS.map((item) => (
                <MenuItem key={item.name} value={item.name}>{item.displayName}</MenuItem>
              ))}
            </Select>
          </Stack>
          {renderSearchInput()}
        </Stack>
        <Stack direction='row' spacing={2} sx={{ mt: 2, display: 'flex'}} alignItems='center'>
          <Typography noWrap variant='body2'>Submitted Date</Typography>
          <DateRangePickerComp value={dateRangeValue} onChange={setDateRangeValue} />
          <Stack direction='row' sx={{ flex:1 }} spacing={1}>
            <Button onClick={onSubmit} fullWidth variant='contained' startIcon={<SearchOutlined />}>Search</Button>
            <Button
              startIcon={<Clear />}
              fullWidth variant='outlined'
              disabled={isDisableClear}
              onClick={handleReset}
            >
              Clear
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </>
  );
};

export default AdminTableHeaderFilterCard;

AdminTableHeaderFilterCard.propTypes = {
  handleUpdateFilterParam: PropTypes.func.isRequired,
};
