import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from '@mui/material';

const AdminTablePageControlsContainer = ({ children }) => {
  return (
    <Card sx={{ mb: 2 }}>
      {children}
    </Card>
  );
};

export default AdminTablePageControlsContainer;

AdminTablePageControlsContainer.propTypes = {
  children: PropTypes.element.isRequired,
};
