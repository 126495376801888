export const appBarHeaderStyles = {
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    cursor: 'pointer'
  },
  mobileNavBox: {
    display: { xs: 'flex', md: 'none' }
  },
  desktopNavBox: {
    display: { xs: 'none', md: 'flex' }
  },
  appBarNavBtn: {
    my: 2,
    color: 'white',
    display: 'block',
    textAlign: 'center',
    mr: 2
  },
};