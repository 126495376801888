import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography
} from '@mui/material';

const ProxyViewModalDetailContainer = ({ label, value }) => {

  return (
    <Stack sx={{ flex: 1 }}>
      <Typography variant='subtitle2' sx={{ fontWeight: '600' }}>
        {label}
      </Typography>
      <Typography variant='body2'>
        {value}
      </Typography>
    </Stack>
  );
};

export default ProxyViewModalDetailContainer;

ProxyViewModalDetailContainer.propTypes = {
  label: PropTypes.string.isRequired,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // Error is showing
};
