import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../constants/RouteConstants';
import { loginAssert } from '../server/userAPI';

const AuthContext = createContext();

const authInitialState = {
  username: null,
  type: null,
  token: null,
  isAuthenticated: false
}

const AuthContextProvider = (props) => {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [authState, setAuthState] = useState(authInitialState);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));

    if (authData?.isAuthenticated && authData?.token) {
      loginAssert()
      .then(({data, success}) => {
        if (success) {
          setAuthState(authData);
          setIsAuthLoading(false);
        }
      })
      .catch(err => console.error(err)) // TODO: handle error
    } else {
      setIsAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated && pathname === routes.login) {
      navigate(routes.admin);
    };
  }, [pathname, authState, navigate]);

  const handleAuthStateUpdate = (authValues) => {
    const { username, token, type } = authValues.data;
    let authData = {
      username: username,
      type: type,
      token: token,
      isAuthenticated: authValues.success
    };
    setAuthState(authData);
    localStorage.setItem('auth', JSON.stringify(authData));
  };

  const signOut = () => {
    localStorage.removeItem('auth');
    setAuthState(authInitialState);
  };

  return (
    <AuthContext.Provider
      value={{
        signOut,
        authState,
        setAuthState,
        isAuthLoading,
        handleAuthStateUpdate,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
};

export { AuthContext, AuthContextProvider };
