import React from 'react';
import { Container, Button, Typography, Divider, Stack, Box } from '@mui/material';
import "../styles/landingStyles.css"
import BackgroundSlider from '../lib/BackgroundSlider';
import AppBarHeader from '../components/AppBarHeader';
import MV_white_logo from '../images/MV_white_logo.png';
import image1 from '../images/landing_background/bg_1.jpg';
import image2 from '../images/landing_background/bg_2.jpg';
import image3 from '../images/landing_background/bg_3.jpg';
import image4 from '../images/landing_background/bg_4.jpg';
import image5 from '../images/landing_background/bg_5.jpg';
import { Link } from 'react-router-dom';
import routes from '../constants/RouteConstants';
import { landingPageStyles } from '../styles/landingPageStyles';

const LandingPage = () => {
  return (
    <>
      <AppBarHeader/>
      <Container sx={landingPageStyles.container}>
        <Stack>
          <img alt='logo' src={MV_white_logo} height={150} style={{ objectFit: 'contain' }} />
          <Typography textAlign='center' variant='h3' sx={{ fontWeight: 'bold', color: '#fff' }}>Melbourne Village</Typography>
        </Stack>
        <Divider sx={landingPageStyles.divider}/>
        <Box sx={landingPageStyles.proxyBtnBox}>
          <Typography sx={{color: '#fff', mb: 3}} variant='h5' gutterBottom>Owners Corporation</Typography>
          <Button
            variant='contained'
            sx={{ p: 2 }}
            component={Link}
            to={routes.proxyForm}
          >
            Proxy Form
          </Button>
        </Box>
      </Container >
      <BackgroundSlider
        images={[image1, image2, image3, image4, image5]}
        duration={10}
        transition={2}
      />
    </>
  );
};

export default LandingPage;
