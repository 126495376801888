import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { addYears } from 'date-fns';
import { TextField } from '@mui/material';

const DateInputComponent = React.forwardRef((props, ref) => {
  const {
    placeholder,
    formikProps,
    formikMeta,
    name,
    isDisabled,
  } = props;
  
  const [value, setValue] = useState(null);
  const [calenderOpen, setCalenderOpen] = useState(false);

  const clearDate = () => {
    setValue(null);
  };

  const setDefaultValue = (defaultValue) => {
    !value && setValue(defaultValue);
  };

  React.useImperativeHandle(ref, () => ({
    clearDate,
    setDefaultValue,
  }));

  const handleCalenderOpen = () => {
    setCalenderOpen(true);
  };

  const handleCalenderClose = () => {
    setCalenderOpen(false);
  };

  const handleDateChange = (newValue) => {
    setValue(newValue);
    formikProps.setFieldValue(name, newValue);
    if (Boolean(formikMeta.error)) {
      formikProps.setFieldError(name, undefined)
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label=''
        inputRef={ref}
        name={name}
        showTodayButton
        open={calenderOpen}
        onOpen={handleCalenderOpen}
        onClose={handleCalenderClose}
        value={value}
        disablePast
        maxDate={addYears(new Date(), 1)}
        disabled={isDisabled}
        inputFormat='yyyy-MM-dd'
        onChange={handleDateChange}
        inputProps={{
          placeholder: placeholder
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            variant='standard'
            disabled={isDisabled}
            error={Boolean(formikMeta.error)}
            helperText={formikMeta.error}
            onClick={handleCalenderOpen} />
        }
      />
    </LocalizationProvider>
  );
});

export default DateInputComponent;

DateInputComponent.defaultProps = {
  isDisabled: false,
};

DateInputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  formikProps: PropTypes.object.isRequired,
  formikMeta: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};
