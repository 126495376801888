import { blueGrey, red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let customMUITheme = createTheme({
  palette: {
    primary: {
      main: '#10103b'
    },
    secondary: blueGrey,
    warning: {
      main: red[600]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          ":disabled": {
            color: '#fff',
            backgroundColor: '#575775'
          }
        },
      }
    }
  }
});
customMUITheme = responsiveFontSizes(customMUITheme);

export default customMUITheme;
