import React, { useContext } from 'react';
import {
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import routes from './constants/RouteConstants';
import customMUITheme from './styles/customMUITheme';
import ContainerWrapper from './components/ContainerWrapper';
import { AuthContext, AuthContextProvider} from './contexts/AuthContext';

// Route elements
import ProxyForm from './pages/ProxyForm';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import LoadingPage from './pages/LoadingPage';
import AdminHomeContextWrapper from './components/AdminTable/AdminHomeContextWrapper';

const PrivateRoutes = () => {
  const { authState, isAuthLoading } = useContext(AuthContext);

  if (isAuthLoading) {
    return <LoadingPage/>;
  }

  if (!authState?.isAuthenticated) {
    return <Navigate replace to="/login" />;
  }
  return <Outlet />;
};

function App() {
  return (
    <ThemeProvider theme={customMUITheme}>
      <AuthContextProvider>
        <SnackbarProvider maxSnack={2} preventDuplicate>
          <Routes>
            <Route path={routes.landing} element={<LandingPage />} />
            <Route element={<ContainerWrapper />}>
              <Route path={routes.login} element={<Login />} />
              <Route path={routes.proxyForm} element={<ProxyForm />} />
              <Route element={<PrivateRoutes />}>
                <Route path={routes.admin} element={<AdminHomeContextWrapper />} />
              </Route>
              <Route path="*" element={<Navigate replace to={routes.landing} />} />
            </Route>
          </Routes>
        </SnackbarProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
};

export default App;
