export const loginStyles = {
  container: { 
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: 5,
    width: {
      xs: '90%',
      sm: '50%',
      md: '30%'
    },
    marginTop: '-5%'
  },
  titleContainerBox: {display: 'flex', flexDirection: 'column', marginBottom: { xs: 2, sm: 5 }},
  inputContainerBox: { display: 'flex', flexDirection: 'column' },
};