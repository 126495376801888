import React from 'react';
import Lottie from "lottie-react";
import { Dialog, DialogContent, Typography } from '@mui/material';
import loadingAnimation from '../../animations/loading_animation.json';

const style = {
  height: 300,
  with: 300
};

const DownloadModal = ({ visibility }) => {

  return (
    <Dialog
      open={visibility}
      disableEscapeKeyDown
    >
      <DialogContent>
        <Lottie
          animationData={loadingAnimation}
          loop
          autoPlay
          style={{width: 200, height: 200}}
        />
        <Typography textAlign='center' variant='body1'>Preparing download...</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;