import React, { useContext } from 'react';
import {
  Box,
  Stack,
  Divider,
  Typography
} from '@mui/material';
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined
} from '@mui/icons-material';
import { AdminContext } from '../../contexts/AdminContext';
import ProxyViewModalDetailContainer from './ProxyViewModalDetailContainer';
import { format } from 'date-fns';
import { ANSWER_TYPE } from '../../constants/ProxyQuestionConstants';

const ProxyViewModalContent = () => {
  const { proxyModalState, questionsAndCategories } = useContext(AdminContext);
  const { modalData } = proxyModalState;
  
  if (!modalData) {
    return <></>;
  }

  const lotIds = modalData.ProxyLots.flatMap((proxyLot) => proxyLot.lotId) || [] ;
  const formattedLotIds = lotIds.length > 1 ? lotIds.join(', ') : lotIds;

  const renderData = (data) => {
    if (data) {
      return data;
    }
    return '- N/A -';
  };

  const renderProxyAnswer = (foundAnswer) => {
    let answerValue = null;
    if (foundAnswer.question_template.answerDataType === ANSWER_TYPE.DATE) {
      answerValue = format(new Date(foundAnswer.answer), 'dd-MM-yyyy')
    }
    if (foundAnswer.question_template.answerDataType === ANSWER_TYPE.LONG_TEXT) {
      answerValue = foundAnswer.answer
    }

    return <Typography sx={{ml: 6, mt: 1, fontStyle: 'italic'}} variant='body1'>{answerValue}</Typography>;
  }

  return (
    <Box>
      <Typography variant='body1' color='secondary' gutterBottom sx={{ fontWeight: '600', mb: 2 }}>
        Owner Details
      </Typography>
      <Stack direction='row' spacing={3} sx={{ display: 'flex', mb: 3 }}>
        <ProxyViewModalDetailContainer
          label='Name(s) of Lot Owners'
          value={renderData(modalData.lotOwner[0].name)}
        />
        <ProxyViewModalDetailContainer
          label='Address'
          value={renderData(modalData.lotOwner[0].address)}
        />
      </Stack>
      <Divider sx={{ mb: 3 }} />
      <Typography variant='body1' color='secondary' gutterBottom sx={{ fontWeight: '600', mb: 2 }}>
        Owner(s) Lot Number(s)
      </Typography>
      <ProxyViewModalDetailContainer
        label='Lot number(s)'
        value={renderData(formattedLotIds)}
      />
      <Divider sx={{ mb: 3, mt: 3 }} />
      <Typography variant='body1' color='secondary' gutterBottom sx={{ fontWeight: '600', mb: 2 }}>
        Authorised Details
      </Typography>
      <Stack direction='row' spacing={3} sx={{ display: 'flex', mb: 3 }}>
        <ProxyViewModalDetailContainer
          label='Name(s) of Authorised'
          value={renderData(modalData.proxyPerson[0].name)}
        />
        <ProxyViewModalDetailContainer
          label='Address'
          value={renderData(modalData.proxyPerson[0].address)}
        />
      </Stack>
      <Divider sx={{ mb: 3 }} />
      <Typography variant='body1' color='secondary' gutterBottom sx={{ fontWeight: '600', mb: 2 }}>
        Proxy Answers
      </Typography>
      {questionsAndCategories.sort((a, b) => a.sequence - b.sequence).map((category) => (
        <Box key={category.id} sx={{ display: 'flex', flexDirection: 'column', my: 3 }}>
          <Typography variant='body1' sx={{ fontWeight: 'bold', mr: 3 }}>{category.label}</Typography>
          {category.question_templates.sort((a, b) => a.sequence - b.sequence).map((question) => {
            const foundAnswer = modalData.QuestionAnswers.find((answer) => answer.questionId === question.id);
            return (
              <Stack key={question.id} sx={{ p: 1 }}>
                <Stack direction='row' spacing={2}>
                  {foundAnswer ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
                  <Typography gutterBottom variant='subtitle2' sx={{ fontWeight: '600' }}>{question.question}</Typography>
                </Stack>
                {foundAnswer && renderProxyAnswer(foundAnswer)}
              </Stack>
            )
          })}
        </Box>
      ))}
      <Divider sx={{ mb: 3 }} />
      <Typography variant='body1' color='secondary' gutterBottom sx={{ fontWeight: '600', mb: 2 }}>
        Additional Info
      </Typography>
      <Stack direction='row' spacing={3} sx={{ display: 'flex', mb: 3 }}>
        <ProxyViewModalDetailContainer
          label='Owner(s) email'
          value={renderData(modalData.email)}
        />
        <ProxyViewModalDetailContainer
          label='Submit Date'
          value={renderData(format(new Date(modalData.createdAt), 'dd-MM-yyyy'))}
        />
      </Stack>
    </Box>
  );
};

export default ProxyViewModalContent;