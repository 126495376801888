import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Stack } from '@mui/material';
import QuestionInputComponent from './QuestionInputComponent';
import { ANSWER_TYPE } from '../../constants/ProxyQuestionConstants';

const QuestionInputWithCheckbox = (props) => {
  const {
    id,
    categoryCode,
    questionLabel,
    answerType,
    placeholder,
    selectedCheckboxId,
    setSelectedCheckboxId,
    formikProps,
    isDisabled,
    defaultValue
  } = props;

  const inputRef = useRef(null);
  
  const isCheckedQuestion = () => {
    const found = selectedCheckboxId.find((selected) => selected.category === categoryCode && selected.questionId === id)
    if (found) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (defaultValue && isCheckedQuestion()) {
      inputRef?.current?.setDefaultValue(defaultValue);
      if (!formikProps.values[id]) {
        formikProps.setFieldValue(id, defaultValue);
      }
    }
  }, [defaultValue, formikProps, id]);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    const filteredExistingCategorySelection = selectedCheckboxId.filter((selected) => selected.category !== categoryCode);
    if (checked) {
      const foundSelectedSameCategory = selectedCheckboxId.find((selected) => selected.category === categoryCode)
      if (foundSelectedSameCategory) {
        formikProps.setFieldValue(foundSelectedSameCategory.questionId, undefined)
        formikProps.setFieldError(foundSelectedSameCategory.questionId, undefined)
      }
      if (!isCheckedQuestion()) {
        if (ANSWER_TYPE.BOOLEAN === answerType) {
          formikProps.setFieldValue(id, true)
        }
        const newSelection = {
          category: categoryCode,
          questionId: id
        }
        setSelectedCheckboxId([...filteredExistingCategorySelection, newSelection])
      }
      return;
    } else {
      setSelectedCheckboxId([...filteredExistingCategorySelection])
      if (inputRef.current?.clearDate) {
        inputRef.current.clearDate();
      } else if (inputRef.current) {
        inputRef.current.value = '';
      }
      formikProps.setFieldValue(id, undefined);
      formikProps.setFieldError(id, undefined);
    }
  };

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Checkbox disabled={isDisabled} checked={isCheckedQuestion()} onChange={handleCheckboxChange} />
      <QuestionInputComponent
        id={id}
        ref={inputRef}
        isDisabled={isDisabled || !isCheckedQuestion()}
        questionLabel={questionLabel}
        answerType={answerType}
        placeholder={placeholder}
      />
    </Stack>
  );
};

export default QuestionInputWithCheckbox;

QuestionInputWithCheckbox.defaultProps = {
  isDisabled: false,
  defaultValue: null,
};

QuestionInputWithCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  categoryCode: PropTypes.string.isRequired,
  questionLabel: PropTypes.string.isRequired,
  answerType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedCheckboxId: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedCheckboxId: PropTypes.func.isRequired,
  formikProps: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
