import React from 'react';
import { Container } from '@mui/material';
import MV_black_logo from '../images/MV_black_logo.png';
import '../styles/loadingPageStyles.css';

const LoadingPage = () => {
  return (
    <Container
      sx={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <img className='logo-img' src={MV_black_logo} />
    </Container>
  );
};

export default LoadingPage;
