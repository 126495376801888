import React, {useContext, useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import Lottie from "lottie-react";
import loadingAnimation from '../../animations/loading_animation.json';
import warningAnimation from '../../animations/warning_animation.json';
import ProxyDeleteAllConfirmationDialog from "./ProxyDeleteAllConfirmationDialog";
import {AdminContext} from "../../contexts/AdminContext";

const ProxyAllDeleteModal = () => {

    const { allDeleteModalState, closeDeleteAllProxyDialog, deleteAllProxy } = useContext(AdminContext);
    const { visibility, isDeleting } = allDeleteModalState;

    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

    const handleClose = () => {
        if (isDeleting) {
            return;
        }
        closeDeleteAllProxyDialog();
    };

    const handleDeleteSelect = () => {
        setIsShowConfirmDialog(true);
    };

    const closeConfirmDialog = () => {
        setIsShowConfirmDialog(false);
    }

    const handleDeleteConfirm = () => {
        closeConfirmDialog();
        deleteAllProxy();
    }

    const renderLoader = () => {
        return (
            <>
                <Lottie
                    animationData={loadingAnimation}
                    loop
                    autoPlay
                    style={{ width: 200, height: 200, display: 'flex', alignSelf: 'center' }}
                />
                <Typography variant='body2' align='center'>Deleting all records...</Typography>
            </>
        );
    };

    const renderDeleteContent = () => {
        return (
            <Stack sx={{ mt: 2 }}>
                <Lottie
                    animationData={warningAnimation}
                    loop={false}
                    autoPlay
                    style={{ width: 200, height: 200, display: 'flex', alignSelf: 'center' }}
                />
                <Typography variant='body1'>
                    This action will delete all submitted proxy records from the server!
                </Typography>
                <Typography align='center' variant='caption' sx={{ color: 'warning.main', fontWeight: 'bold', mt: 1 }}>
                    This action is non-reversible and can not be restored.
                </Typography>
            </Stack>
        );
    };

    return (
        <Dialog
            open={visibility}
            onClose={handleClose}
            disableEscapeKeyDown={isDeleting}
            onBackdropClick={handleClose}
        >
            <DialogContent>
                <Typography variant='body1' sx={{fontWeight: 'bold'}} gutterBottom>Delete All Proxy Records</Typography>
                {isDeleting ? renderLoader() : renderDeleteContent()}
            </DialogContent>
            <ProxyDeleteAllConfirmationDialog
                visibility={isShowConfirmDialog}
                handleClose={closeConfirmDialog}
                onDeleteConfirm={handleDeleteConfirm}
            />
            <DialogActions sx={{mb: 2, mr: 2}}>
                <Button variant='outlined' disabled={isDeleting} color='warning' onClick={handleDeleteSelect}>Delete</Button>
                <Button variant='contained' disabled={isDeleting} onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProxyAllDeleteModal;
