import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Typography, Button, Container } from '@mui/material';
import {
  Link, useNavigate, useLocation
} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { LogoutOutlined } from '@mui/icons-material';
import routes from '../constants/RouteConstants';
import MV_white_logo from '../images/MV_white_logo.png';
import { AuthContext } from '../contexts/AuthContext';
import { appBarHeaderStyles } from '../styles/appbarHeaderStyles';
import AppBarNavButton from './AppBarNavButton';

const pagesArray = {
  landing: {
    title: 'Home',
    navLink: routes.landing
  },
  proxyForm: {
    title: 'Proxy Form',
    navLink: routes.proxyForm
  },
  login: {
    title: 'Login',
    navLink: routes.login
  },
  admin: {
    title: 'Admin View',
    navLink: routes.admin
  }
};

const AppBarHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authState, signOut } = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [pages, setPages] = useState([]);

  const isLandingPage = pathname === routes.landing;

  useEffect(() => {
    const { isAuthenticated } = authState;
    let pageArray;

    if (pathname === routes.landing) {
      pageArray = [pagesArray.proxyForm];
    }

    if (pathname === routes.proxyForm) {
      pageArray = [pagesArray.landing];
    }

    if (pathname === routes.admin) {
      pageArray = [pagesArray.landing, pagesArray.proxyForm];
    }

    if (pathname === routes.login) {
      pageArray = [pagesArray.landing, pagesArray.proxyForm]
    }

    if ([routes.landing, routes.proxyForm].includes(pathname)) {
      isAuthenticated ? pageArray.push(pagesArray.admin) : pageArray.push(pagesArray.login);
    }
    
    setPages(pageArray);
  }, [pathname, authState])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onLogoSelect = () => {
    navigate(routes.landing)
  };

  const onSignOutSelect = () => {
    signOut();
  };

  return (
    <AppBar position='sticky' style={isLandingPage ? appBarHeaderStyles.appBar : {}}>
      <Toolbar>
        <Container sx={appBarHeaderStyles.container}>
          <Box sx={appBarHeaderStyles.logoContainer} component='button' onClick={onLogoSelect}>
            <img alt='logo' src={MV_white_logo} height='50px' style={{ objectFit: 'contain' }} />
            <Typography variant='h6'>Melbourne Village</Typography>
          </Box>
          <Box sx={appBarHeaderStyles.mobileNavBox}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({title, navLink}) => (
                <MenuItem component={Link} key={title} to={navLink} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              ))}
              {authState?.isAuthenticated && <MenuItem onClick={onSignOutSelect}>Log Out</MenuItem>}
            </Menu>
          </Box>
          <Box sx={appBarHeaderStyles.desktopNavBox}>
            {pages.map(({ title, navLink }) => (
              <AppBarNavButton
                key={title}
                title={title}
                navLink={navLink}
              />
            ))}
            {authState?.isAuthenticated && <Button
              sx={{ my: 2, color: 'white' }}
              variant="outlined"
              startIcon={<LogoutOutlined />}
              onClick={onSignOutSelect}
            >
              Logout
            </Button>}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
