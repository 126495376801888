import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppBarHeader from './AppBarHeader';

const ContainerWrapper = () => {

  return (
    <>
      <CssBaseline />
      <AppBarHeader/>
      <Container
        sx={{ height: '90vh' }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default ContainerWrapper;