import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material';
import AdminTableHeader from './AdminTableHeader';
import AdminTableBody from './AdminTableBody';

const AdminTable = (props) => {
  const { tableData } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: 'white',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          p: -10,
          maxHeight: '57Vh'
        }}
      >
        <Table stickyHeader>
          <AdminTableHeader />
          <AdminTableBody tableData={tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, {label: 'All', value: tableData.length}]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default AdminTable;

AdminTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
