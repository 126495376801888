import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Stack, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import { addYears } from 'date-fns';
import QuestionInputComponent from './QuestionInputComponent';
import { ANSWER_TYPE, CATEGORY_RULE, DEFAULT_QUESTIONS, FIELD_VALIDATIONS } from '../../constants/ProxyQuestionConstants';
import { ProxyUserQuestionContainerStyles } from '../../styles/proxyPageStyles';
import { REQUIRED_FIELD_MSG } from '../../constants/CommonConstants';
import SignatureComponent from '../inputComponents/SignatureComponent';
import QuestionInputWithCheckbox from './QuestionInputWithCheckbox';
import { getValidationSchema } from '../../utils/proxyUtils';
import DefaultQuestionContainer from './DefaultQuestionContainer';
import { submitProxy } from '../../server/proxyAPI';
import ProxySubmitModal from '../DialogModals/ProxySubmitModal';

const initialSubmitDialogState = {
  visibility: false,
  submitId: null,
};

const CategoryRequiredMessage = ({message}) => {
  return (
    <Typography gutterBottom sx={{ fontSize: '0.75rem', color: '#d32f2f' }}>
      {message}
    </Typography>
  );
};

// TODO: This component should be removed since this breaks dynamic capability
const RenderQB = ({ id, formikProps, questionLabel, defaultValue, selectedCheckboxId, setSelectedCheckboxId }) => {

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(defaultValue);
    if (defaultValue) {
      formikProps.setFieldValue(id, defaultValue);
      formikProps.setFieldError(id, undefined);
    }
    // eslint-disable-next-line
  }, [defaultValue, id, setIsChecked]);

  useEffect(() => {
    const found = selectedCheckboxId.find((selected) => selected.questionId === id);
    if (isChecked) {
      if (!found) {
        const newSelection = {
          category: 'B',
          questionId: id
        }
        setSelectedCheckboxId([...selectedCheckboxId, newSelection]);
      }
    } else if (found) {
      setSelectedCheckboxId(selectedCheckboxId.filter((selected) => selected.category !== 'B'))
    }
  }, [isChecked, selectedCheckboxId, id, setSelectedCheckboxId]);

  const handleCheckboxChange = (e, formik) => {
    const { checked } = e.target;
    setIsChecked(checked);
    if (checked) {
      formik.setFieldValue(id, true);
      formik.setFieldError(id, undefined);
    } else {
      formik.setFieldValue(id, undefined);
      formik.setFieldError(id, undefined);
    }
  };

  return (
    <Field name={id}>
      {({
        field: { name },
        form,
        meta,
      }) => {
        return (
          <Stack sx={{ mb: 2 }}>
            <Stack direction='row' spacing={2}>
              <Checkbox
                name={name}
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(e, form)}
              />
              <Typography variant='body2'>{questionLabel}</Typography>
            </Stack>
          </Stack>
        )
      }}
    </Field>
  );
};

const ProxyUserQuestionContainer = (props) => {
  const { categoryQuestions } = props;

  const { enqueueSnackbar } = useSnackbar();
  const signaturePadRef = React.useRef(null);

  const [selectedCheckboxId, setSelectedCheckboxId] = useState([]);
  const [showCategoryAError, setShowCategoryAError] = useState(false);
  const [proxyValidationSchema, setProxyValidationSchema] = useState(FIELD_VALIDATIONS);
  const [showEmptySignError, setShowEmptySignError] = useState(false);
  const [submitDialogState, setSubmitDialogState] = useState(initialSubmitDialogState);

  useEffect(() => {
    const defaultSelectedQs = categoryQuestions.map((category) => {
      const foundFirstSeqQ = category.question_templates.find((question_template) => question_template.sequence === 1);
      if (foundFirstSeqQ) {
        return {
          category: category.code,
          questionId: foundFirstSeqQ.id
        }
      }
    });

    setSelectedCheckboxId(defaultSelectedQs)
  }, [categoryQuestions]);
  

  useEffect(() => {
    let validationSchema = FIELD_VALIDATIONS;
    if (selectedCheckboxId.length > 0) {
      const selectedFieldValidation = [];
      selectedCheckboxId.forEach((selectedCheckboxQuestion) => {
        const selectedQuestion = categoryQuestions.flatMap((category) => category.question_templates)
          .find((question) => question.id === selectedCheckboxQuestion.questionId);

        const answerType = selectedQuestion.answerDataType === ANSWER_TYPE.LONG_TEXT ?
          'string' : ANSWER_TYPE[selectedQuestion.answerDataType].toLowerCase();
        selectedFieldValidation.push({
          name: selectedCheckboxQuestion.questionId,
          label: selectedCheckboxQuestion.questionId,
          validationType: answerType,
          validations: [
            {
              type: 'required',
              params: [REQUIRED_FIELD_MSG]
            }
          ]
        })
      })
      validationSchema = [...FIELD_VALIDATIONS, ...selectedFieldValidation];
    }
    setProxyValidationSchema(validationSchema);
    setShowCategoryAError(false);
  }, [selectedCheckboxId, categoryQuestions]);

  const handleProxyFormSubmit = (values, formikBag) => {
    const questionIds = categoryQuestions.flatMap((category) =>
      category.question_templates).map((question => question.id));
    signaturePadRef.current.off();
    const dynamicQIds = [];
    Object.keys(values).forEach(key => {
      if (questionIds.includes(key)) {
        dynamicQIds.push(key)
      }
    });
    const dynamicQuestions = categoryQuestions.flatMap((category) => category.question_templates);
    const formData = {
      lotOwner: {
        name: values.OWNER_NAME,
        address: values.OWNER_ADDRESS
      },
      proxyPerson: {
        id: values.AUTH_NAME,
        address: values.AUTH_ADDRESS
      },
      lotNumbers: values.LOT_NUMBERS.replaceAll(' ', '').split(','),
      questions: dynamicQIds.map((id) => {
        const foundQuestion = dynamicQuestions.find((question) => question.id === id);
        const questionAnswer = foundQuestion.answerDataType === ANSWER_TYPE.DATE ? values[id].toISOString() : `${values[id]}`;
        return (
          {
            question: foundQuestion,
            answer: questionAnswer
          }
        )
      }),
      email: values.EMAIL.toLowerCase(),
      printedName: values.SIGN_PRINT_NAME,
    }

    submitProxy(
      formData,
      signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png'))
      .then((response) => {
        if (response.success) {
          setSubmitDialogState({
            visibility: true,
            submitId: response.data.formSave.id
          })
        } else {
          throw response;
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.toString(), { variant: 'error', onExit: formikBag.setSubmitting(false) })
      })
  };

  const handleSubmit = (values, formikBag) => {
    const selectedCategories = selectedCheckboxId.map((selected) => selected.category)
      .sort((a, b) => a.localeCompare(b));
    if (selectedCategories.includes('A')) {
      if (signaturePadRef.current.isEmpty()) {
        formikBag.setSubmitting(false);
        setShowEmptySignError(true);
        return;
      }
      return handleProxyFormSubmit(values, formikBag);
    } else {
      setShowCategoryAError(true);
    }
    formikBag.setSubmitting(false);
    return;
  };

  const handleSubmitClick = (formik) => {
    formik.handleSubmit();
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(proxyValidationSchema)}
      validateOnChange={false}
      initialValues={{
        AUTH_ADDRESS: DEFAULT_QUESTIONS.AUTH_ADDRESS.defaultValue
      }}
    >
      {formik => {
        return (
          <>
            <DefaultQuestionContainer isDisabled={formik.isSubmitting} />
            <Typography variant='subtitle2' gutterBottom sx={ProxyUserQuestionContainerStyles.categoryLabel}>
              As my/our proxy:
            </Typography>
            {categoryQuestions.sort((a, b) => a.sequence - b.sequence).map((category) => (
              <Box key={category.id} sx={{ display: 'flex', flexDirection: 'column', my: 3 }}>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mr: 3 }}>{category.label}</Typography>
                {(showCategoryAError && category.code === 'A') && <CategoryRequiredMessage message={REQUIRED_FIELD_MSG}/> }
                {category.question_templates.sort((a, b) => a.sequence - b.sequence).map((question, index) => {
                  let defaultValue = null;
                  if (['A', 'B'].includes(category.code) && question.sequence === 1) {
                    defaultValue = category.code === 'A' ? addYears(new Date(), 1) : true;
                  }
                  if (category.code === 'A') {
                    return (
                      <React.Fragment key={question.id}>
                        <QuestionInputWithCheckbox
                          id={question.id}
                          categoryCode={category.code}
                          formikProps={formik}
                          isDisabled={formik.isSubmitting}
                          selectedCheckboxId={selectedCheckboxId}
                          setSelectedCheckboxId={setSelectedCheckboxId}
                          questionLabel={question.question}
                          answerType={question.answerDataType}
                          placeholder={question.placeholder}
                          defaultValue={defaultValue}
                        />
                        {((category.rule === CATEGORY_RULE.AT_LEAST_ONE) && (index !== category.question_templates.length - 1)) &&
                          <Typography variant='body1' sx={{ mt: 3, mb: 2 }}>OR</Typography>
                        }
                      </React.Fragment>
                    )
                    // TODO: This component should be removed since this breaks dynamic capability
                  } else if (category.code === 'B') {
                    return (
                      <RenderQB
                        key={question.id}
                        id={question.id}
                        formikProps={formik}
                        questionLabel={question.question}
                        defaultValue={defaultValue}
                        selectedCheckboxId={selectedCheckboxId}
                        setSelectedCheckboxId={setSelectedCheckboxId}
                      />
                    );
                  }
                  return null;
                })}
              </Box>
            ))}
            <SignatureComponent
              ref={signaturePadRef}
              showEmptySignError={showEmptySignError}
              setShowEmptySignError={setShowEmptySignError}
            />
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 3 }}>
              <QuestionInputComponent
                id={DEFAULT_QUESTIONS.EMAIL.id}
                questionLabel={DEFAULT_QUESTIONS.EMAIL.question}
                answerType={DEFAULT_QUESTIONS.EMAIL.answerDataType}
                isRequired={DEFAULT_QUESTIONS.EMAIL.isRequired}
                placeholder={DEFAULT_QUESTIONS.EMAIL.placeholder}
                isDisabled={formik.isSubmitting}
              />
              <QuestionInputComponent
                id={DEFAULT_QUESTIONS.SIGN_PRINT_NAME.id}
                questionLabel={DEFAULT_QUESTIONS.SIGN_PRINT_NAME.question}
                answerType={DEFAULT_QUESTIONS.SIGN_PRINT_NAME.answerDataType}
                isRequired={DEFAULT_QUESTIONS.SIGN_PRINT_NAME.isRequired}
                placeholder={DEFAULT_QUESTIONS.SIGN_PRINT_NAME.placeholder}
                isDisabled={formik.isSubmitting}
              />
            </Stack>
            <Stack direction='column' sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              {(Object.keys(formik.errors).length > 0 || showEmptySignError || showCategoryAError) &&
                <CategoryRequiredMessage message='Please fill all mandatory fields before submitting!'/>}
              <LoadingButton
                loading={formik.isSubmitting}
                variant='contained'
                onClick={() => handleSubmitClick(formik)}
                sx={{ width: { xs: '100%', md: '50%' }, mb: 2 }}
              >
                Submit Proxy Form
              </LoadingButton>
            </Stack>
            {submitDialogState.visibility &&
              <ProxySubmitModal
                submitDialogState={submitDialogState}
                setSubmitDialogState={setSubmitDialogState}
                email={formik.values[DEFAULT_QUESTIONS.EMAIL.id]}
              />
            }
          </>
        );
      }}
    </Formik>
  );
};

export default ProxyUserQuestionContainer;

ProxyUserQuestionContainer.propTypes = {
  categoryQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
