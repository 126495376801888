import React from 'react';
import { AdminContextProvider } from '../../contexts/AdminContext';
import AdminHome from '../../pages/AdminHome';

const AdminHomeContextWrapper = () => {
  return (
    <AdminContextProvider>
      <AdminHome />
    </AdminContextProvider>
  );
};

export default AdminHomeContextWrapper;